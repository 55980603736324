import React from "react";
import { useTranslation } from "react-i18next";

import SettingsIcon from "@mui/icons-material/Settings";
import {
  Edit as EditIcon,
  Search as SearchIcon,
  Clear as ClearIcon,
  AddCircleOutline as AddCircleOutlineIcon,
  LocalOfferOutlined as LocalOfferOutlinedIcon,
  LocalOffer as LocalOfferIcon,
  DeleteOutline as DeleteOutlineIcon,
  ShortText as ShortTextIcon,
  AccessTime as AccessTimeIcon,
} from "@mui/icons-material";
import {
  Button,
  Chip,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";

import useIsMobile from "../../../hooks/useIsMobile";
import {
  Alarme,
  Ctrl,
  CtrlModels,
  Setpoints,
} from "../../../services/types/ctrls";
import TopicTitle from "../../TopicTitle";
import { getCtrlConfig } from "../parser";
import { Params } from "../parser/types";
import { Row, RowTitle } from "./Rows";
import { ParamEdit } from "../../../contexts/ControllerEditContext";
import ModalEdit from "./Modal/ModalEdit";
import { ThemeModeContext } from "../../../contexts/ThemeModeContext";
import ModalEditRecInt from "./Modal/ModalEditRecInt";
import { getConfig } from "../../../services/data/arcsys";
import { useParams } from "react-router-dom";
import { ToastContext } from "../../../contexts/ToastContext";
import { ControllerEditContext } from "../../../contexts/ControllerEditContext";
import ModalAddTag from "./Modal/Tag/ModalAdd";
import ModalSelectTag from "./Modal/Tag/ModalSelect";
import { green } from "@mui/material/colors";
import ModalDeleteTag from "./Modal/Tag/ModalDelete";
import { getRawCtrlConfig } from "../../../config";

interface SettingsTableProps {
  model: CtrlModels;
  settings: Ctrl;
  permissionToEdit: boolean;
  permissionToEditTag: boolean;
  devicePermission: boolean;
  deviceUpdateInterval: number;
  tagName: string;
  tag_id: string;
  setTagEdited: (e: boolean) => void;
  estado: number;
}

const SettingsTable = ({
  settings,
  model,
  permissionToEdit,
  permissionToEditTag,
  devicePermission,
  deviceUpdateInterval,
  tagName,
  tag_id,
  setTagEdited,
  estado,
}: SettingsTableProps) => {
  const { t } = useTranslation();
  const params = useParams();
  const { theme } = React.useContext(ThemeModeContext);
  const mobile = useIsMobile();
  const [open, setOpen] = React.useState(false);
  const [updateInterval, setUpdateInterval] = React.useState<number>();
  const { toastError } = React.useContext(ToastContext);
  const { setParamToEdit } = React.useContext(ControllerEditContext);
  const [eventSaveIntervalMax, setEventSaveIntervalMax] =
    React.useState<number>();

  // Ações de abrir o modal de adicionar TAG
  const [openModalAdd, setOpenModalAdd] = React.useState<boolean>(false);
  const handleOpenModalAdd = () => setOpenModalAdd(true);
  const handleCloseModalAdd = () => {
    setOpenModalAdd(false);
  };

  // Ações de abrir o modal de selecionar TAG
  const [openModalSelect, setOpenModalSelect] = React.useState<boolean>(false);
  const handleOpenModalSelect = () => setOpenModalSelect(true);
  const handleCloseModalSelect = () => {
    setOpenModalSelect(false);
  };

  // Ações de abrir o modal de deletar TAG
  const [openModalDelete, setOpenModalDelete] = React.useState<boolean>(false);
  const handleOpenModalDelete = () => setOpenModalDelete(true);
  const handleCloseModalDelete = () => {
    setOpenModalDelete(false);
  };

  // Abrir modal de editar intervalo de gravação
  const handleOpen = () => setOpen(true);

  // Fechar modal de editar intervalo de gravação
  const handleClose = () => setOpen(false);

  const tablePadding = useIsMobile() ? 0.3 : 1;
  const ctrlConfig = getCtrlConfig(model);
  const rawCtrlConfig = getRawCtrlConfig(model);

  const {
    alarme: alarmeConfig,
    parametros: parametrosConfig,
    setpoints: setpointsConfig,
  } = ctrlConfig;

  // Barra de pesquisa do Controlador
  const handleSearch = () => {
    let input: any = document.getElementById("myInput");
    let filter = input.value.toUpperCase();
    let table: any = document.getElementById("myTable");
    let tr = table.getElementsByTagName("tr");
    let td;
    let i;
    let txtValue;
    for (i = 0; i < tr.length; i++) {
      td = tr[i].getElementsByTagName("td")[1];
      if (td) {
        txtValue = td.textContent || td.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }
    }
  };

  // Limpar barra de pesquisa do Controlador
  const handleClearSearch = () => {
    (document.getElementById("myInput") as any).value = "";
    handleSearch();
  };

  const { alarme, parametros, setpoints } = settings;
  const rotuloCtrl = settings?.rotulo;

  // Dados da linha 'Nome' da Página do Controlador
  const rotuloParam = {
    description: t("TEXT.NAME"),
    // eslint-disable-next-line no-useless-escape
    value: rotuloCtrl.replace(/[^0-9a-zA-Z:\-_.,\{\}\"\' ]/gi, ""),
    param: "rotulo",
  } as unknown as ParamEdit;

  // Separa a string de referencia e a chave para um objeto
  // "setpoint1" => { param: "setpoint", key: "1" }
  const splitCtrlParamKey = (data: string) => {
    const keys = ["alarme", "detalhamento", "setpoint"];
    const param = keys.find((k) => data.indexOf(k) === 0) as Params;
    const key = data.replace(param, "");
    if (!param || !key) throw Error(`Controller param not found: "${data}"`);
    return { param, key };
  };

  // Verifica se o valor min/max é um número ou referencia a outro valor
  // Se for referencia retorna o valor da referência
  const getReferenceOrNumber = (value: string | number) => {
    // Alguns valores do modelo não apresentam um min/max mas fazem referencia a
    // outro valor no mesmo modelo
    const getReferenceValue = (referenceValue: string) => {
      const { param, key } = splitCtrlParamKey(referenceValue);

      const getParamtrosIndexByCode = (code: string) =>
        parametrosConfig.findIndex((i) => i.code === code);

      const getSetpointKey = (index: string) =>
        `s${+index - 1}` as keyof Setpoints;

      const getAlarmeKey = (index: string) => {
        const keys = Object.keys(alarme);
        return keys[+index] as keyof Alarme;
      };

      switch (param) {
        case "alarme":
          return alarme[getAlarmeKey(key)];
        case "detalhamento":
          return parametros[getParamtrosIndexByCode(key)];
        case "setpoint":
          return setpoints[getSetpointKey(key)];
        default:
          throw Error(`Param not found: "${param}"`);
      }
    };

    if (typeof value === "string") {
      return getReferenceValue(value);
    }
    return value;
  };

  ctrlConfig.parametros.forEach(({ min, max }, i) => {
    if (typeof min !== "number") {
      ctrlConfig.parametros[i].min = getReferenceOrNumber(min);
    }
    if (typeof max !== "number") {
      ctrlConfig.parametros[i].max = getReferenceOrNumber(max);
    }
  });

  ctrlConfig.setpoints.forEach(({ min, max }, i) => {
    if (typeof min !== "number") {
      ctrlConfig.setpoints[i].min = getReferenceOrNumber(min);
    }
    if (typeof max !== "number") {
      ctrlConfig.setpoints[i].max = getReferenceOrNumber(max);
    }
  });

  ctrlConfig.alarme.forEach(({ min, max }, i) => {
    if (typeof min !== "number") {
      ctrlConfig.alarme[i].min = getReferenceOrNumber(min);
    }
    if (typeof max !== "number") {
      ctrlConfig.alarme[i].max = getReferenceOrNumber(max);
    }
  });

  // Checa se há parâmetros no Controlador
  const checkParametrosIsNull =
    parametrosConfig.length === 1 && parametrosConfig[0].visible === false;

  // Checa se há setpoints no Controlador
  const checkSepointsIsNull =
    setpointsConfig.length === 1 && setpointsConfig[0].visible === false;

  // Checa se o setpoint 's0' dos inversores forem maior ou diferente de 0
  const checkFrequencySetpoint =
    rawCtrlConfig?.inversor && rawCtrlConfig?.inversor === true && estado !== 1;

  React.useEffect(() => {
    // Resgatar o valor do Intervalo de Gravação da página do Controlador
    setUpdateInterval(deviceUpdateInterval / 60000);
  }, [deviceUpdateInterval]);

  // Resgatar o intervalo máximo de gravação do Controlador
  React.useEffect(() => {
    getConfig().then((res) => {
      setEventSaveIntervalMax(res.data.eventSaveIntervalMax / 60000);
    });
  }, []);

  // Verificar se o dispositivo tem permissão para editar
  const handleNoPermissionShare = () => {
    toastError(t("TOAST.ERROR.SHARE_DEVICE_PERMISSION"));
  };

  // Abrir o modal de editar parâmetro
  const handleClick = () => {
    setParamToEdit(rotuloParam);
  };

  return (
    <>
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          ml: mobile ? 2 : 0,
          mr: mobile ? 2 : 0,
          mb: 3,
          mt: mobile ? 3 : 2,
          borderRadius: 5,
          backgroundColor: theme === "dark" ? "#181818" : "#F9F9F9",
        }}
      >
        <SearchIcon sx={{ ml: 2 }} />
        <InputBase
          id="myInput"
          onKeyUp={handleSearch}
          onKeyDown={(e: any) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
          sx={{ ml: 2, flex: 1 }}
          placeholder={t("TEXT.SEARCH_CONFIG")}
        />
        <Tooltip title={t("TEXT.CLEAN")}>
          <IconButton
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
            onClick={handleClearSearch}
          >
            <ClearIcon />
          </IconButton>
        </Tooltip>
      </Paper>
      {
        <TableContainer
          id="myTable"
          sx={{
            "& td": { pl: tablePadding, pr: tablePadding },
            overflowX: "hidden",
          }}
        >
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell colSpan={4}>
                  <Grid container justifyContent="space-between">
                    <Grid item alignSelf="center">
                      <TopicTitle icon={<SettingsIcon />}>
                        {t("TEXT.SETTINGS")}
                      </TopicTitle>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <RowTitle>{t("TEXT.GENERAL")}</RowTitle>
              {params.ctrl !== "CTRL36" && (
                <TableRow hover>
                  <TableCell
                    sx={{ textAlign: "center", verticalAlign: "middle" }}
                  >
                    <ShortTextIcon fontSize="large" sx={{ mt: 1 }} />
                  </TableCell>
                  <TableCell sx={{ verticalAlign: "text-top" }}>
                    <Typography
                      sx={{
                        mt: 2,
                        mb: 2,
                        color: theme === "light" ? "#6D6D6D" : "#FFFF",
                        fontSize: 16,
                      }}
                    >
                      {t("TEXT.NAME")}
                    </Typography>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell align="right">
                    <Typography
                      sx={{
                        fontSize: 16,
                        color: theme === "light" ? "#6D6D6D" : "#FFFF",
                      }}
                    >
                      {rotuloCtrl}
                    </Typography>
                  </TableCell>
                  {devicePermission && (
                    <TableCell align="right">
                      {mobile ? (
                        <IconButton
                          color="primary"
                          onClick={
                            permissionToEdit
                              ? handleClick
                              : handleNoPermissionShare
                          }
                        >
                          <EditIcon color="warning" />
                        </IconButton>
                      ) : (
                        <Button
                          size="small"
                          color="warning"
                          onClick={
                            permissionToEdit
                              ? handleClick
                              : handleNoPermissionShare
                          }
                          startIcon={<EditIcon />}
                          sx={{ fontSize: 12.5, fontWeight: 600 }}
                        >
                          {t("BUTTON.EDIT")}
                        </Button>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              )}
              <TableRow hover>
                <TableCell
                  sx={{
                    textAlign: "center",
                    verticalAlign: "middle",
                    maxWidth: params.ctrl === "CTRL36" ? 37 : 180,
                  }}
                >
                  <AccessTimeIcon sx={{ mt: 1, fontSize: 30 }} />
                </TableCell>
                <TableCell sx={{ verticalAlign: "text-top", maxWidth: 330 }}>
                  <>
                    <Typography sx={{ mt: 0.5 }}>
                      {t("TEXT.RECORDING_INTERVAL")}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {`0 a ${eventSaveIntervalMax} min`}
                    </Typography>
                  </>
                </TableCell>
                {params.ctrl === "CTRL36" && <TableCell></TableCell>}
                <TableCell></TableCell>
                <TableCell align="right">
                  <Typography
                    variant="body1"
                    sx={{
                      mt: 0.5,
                      fontSize: mobile ? 14 : 16,
                      color: theme === "light" ? "#6D6D6D" : "#FFFF",
                      pr: mobile ? 2.5 : 0,
                    }}
                  >
                    {updateInterval} min
                  </Typography>
                </TableCell>
                {devicePermission && (
                  <TableCell
                    align={mobile ? "center" : "right"}
                    sx={{ width: mobile ? 50 : 100 }}
                  >
                    {mobile ? (
                      <IconButton
                        color="primary"
                        onClick={
                          permissionToEdit
                            ? handleOpen
                            : handleNoPermissionShare
                        }
                      >
                        <EditIcon color="warning" />
                      </IconButton>
                    ) : (
                      <Button
                        size="small"
                        color="warning"
                        onClick={
                          permissionToEdit
                            ? handleOpen
                            : handleNoPermissionShare
                        }
                        startIcon={<EditIcon />}
                        sx={{ fontSize: 12.5, fontWeight: 600 }}
                      >
                        {t("BUTTON.EDIT")}
                      </Button>
                    )}
                  </TableCell>
                )}
              </TableRow>
              {params.ctrl !== "CTRL36" && (
                <TableRow hover>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      verticalAlign: "middle",
                      maxWidth: params.ctrl === "CTRL36" ? 37 : 180,
                    }}
                  >
                    <LocalOfferOutlinedIcon sx={{ mt: 1, fontSize: 29 }} />
                  </TableCell>
                  <TableCell sx={{ verticalAlign: "middle", maxWidth: 330 }}>
                    <Typography sx={{ mt: 1.2, mb: 1.2, mr: mobile ? 2 : 3 }}>
                      TAG
                    </Typography>
                  </TableCell>
                  {params.ctrl === "CTRL36" && <TableCell></TableCell>}
                  <TableCell></TableCell>
                  <TableCell align="right">
                    {tagName !== "" && tagName !== undefined ? (
                      <Chip
                        label={tagName}
                        avatar={<LocalOfferOutlinedIcon />}
                        color="success"
                        sx={{
                          fontWeight: 700,
                          backgroundColor: green[100],
                          color: green[800],
                          textTransform: "uppercase",
                          fontSize: 12,
                          "& .MuiChip-avatar": {
                            color: "black",
                          },
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </TableCell>
                  <TableCell
                    align={mobile ? "center" : "right"}
                    sx={{ width: mobile ? 50 : 100 }}
                  >
                    {tagName !== "" && tagName !== undefined ? (
                      <>
                        {mobile ? (
                          <IconButton
                            color="primary"
                            onClick={
                              permissionToEditTag
                                ? handleOpenModalDelete
                                : handleNoPermissionShare
                            }
                          >
                            <DeleteOutlineIcon color="warning" />
                          </IconButton>
                        ) : (
                          <Button
                            size="small"
                            color="warning"
                            onClick={
                              permissionToEditTag
                                ? handleOpenModalDelete
                                : handleNoPermissionShare
                            }
                            startIcon={<DeleteOutlineIcon />}
                            sx={{ fontSize: 12.5, fontWeight: 600 }}
                          >
                            {t("TEXT.REMOVE")}
                          </Button>
                        )}
                      </>
                    ) : (
                      <>
                        <Tooltip title={t("TEXT.SELECT_TAG")}>
                          <IconButton
                            color="primary"
                            onClick={
                              permissionToEditTag
                                ? handleOpenModalSelect
                                : handleNoPermissionShare
                            }
                          >
                            <LocalOfferIcon color="warning" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={t("TEXT.CREATE_TAG")}>
                          <IconButton
                            color="primary"
                            onClick={
                              permissionToEditTag
                                ? handleOpenModalAdd
                                : handleNoPermissionShare
                            }
                          >
                            <AddCircleOutlineIcon color="warning" />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              )}
              <Row
                param="alarme"
                config={alarmeConfig}
                values={alarme}
                permissionToEdit={permissionToEdit}
                devicePermission={devicePermission}
                rawCtrlConfig={rawCtrlConfig}
              />
              {!checkSepointsIsNull && (
                <Row
                  param="setpoints"
                  config={setpointsConfig}
                  values={setpoints}
                  permissionToEdit={permissionToEdit}
                  devicePermission={devicePermission}
                  rawCtrlConfig={rawCtrlConfig}
                />
              )}
              {!checkParametrosIsNull && (
                <Row
                  param="parametros"
                  config={parametrosConfig}
                  values={parametros}
                  permissionToEdit={permissionToEdit}
                  devicePermission={devicePermission}
                  checkFrequencySetpoint={checkFrequencySetpoint}
                  rawCtrlConfig={rawCtrlConfig}
                />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      }
      <ModalEdit />
      <ModalEditRecInt
        updateInterval={updateInterval}
        handleOpen={open}
        handleClose={handleClose}
        setUpdateInterval={setUpdateInterval}
      />
      <ModalAddTag
        handleOpen={openModalAdd}
        handleClose={handleCloseModalAdd}
        setTagEdited={setTagEdited}
      />
      <ModalSelectTag
        handleOpen={openModalSelect}
        handleClose={handleCloseModalSelect}
        setTagEdited={setTagEdited}
      />
      <ModalDeleteTag
        tagName={tagName}
        tag_id={tag_id}
        handleOpen={openModalDelete}
        handleClose={handleCloseModalDelete}
        setTagEdited={setTagEdited}
      />
    </>
  );
};

export default SettingsTable;

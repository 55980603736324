import { useTranslation } from "react-i18next";

import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TextField, yupEmail } from "../../components/Form";
import ErrorMessage from "../../components/ErrorMessage";
import {
  loginBackground,
  loginComponentBackground,
} from "../../components/Login/BackgroundImages";
import { LOGIN } from "../../Routes/constants";
import { useNavigate } from "react-router-dom";
import { forgot } from "../../services/data/auth";
import React from "react";
import { ToastContext } from "../../contexts/ToastContext";
import { AuthContext } from "../../contexts/AuthContext";

const Register = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { toastSuccess, toastError } = React.useContext(ToastContext);
  const { signOut } = React.useContext(AuthContext);

  React.useEffect(() => {
    const fetchStatus = () => {
      signOut();
    };
    fetchStatus();
    const statusInterval = setInterval(fetchStatus, 10000000);
    return () => clearInterval(statusInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: yupEmail,
    }),
    onSubmit: (values) => {
      // Requisição POST que aceita um endereço e-mail para a recuperação da senha da conta
      forgot({ login: values.email })
        .then(() => {
          toastSuccess(t("TOAST.SUCCESS.RESET_PASSWORD_EMAIL"));
          signOut();
        })
        .catch((err) => {
          const error = err.response.status;
          if (error === 403) {
            toastError(t("TOAST.ERROR.EMAIL_NOT_REGISTERED"));
          } else {
            toastError(t("TOAST.ERROR.CONTROLLER_CHANGED"));
          }
        });
    },
  });

  // Validação do formulário
  const isFormValid = formik.dirty && formik.isValid;

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      sx={{
        height: "100vh",
        backgroundImage: loginBackground,
        backgroundSize: "cover",
      }}
    >
      <Grid item>
        <Card
          sx={{
            minWidth: 350,
            maxWidth: 400,
            p: 2,
            backgroundImage: loginComponentBackground,
            ml: 2,
            mr: 2,
          }}
          elevation={5}
        >
          <CardContent sx={{ position: "relative" }}>
            <Grid
              container
              direction="column"
              spacing={3}
              component="form"
              onSubmit={formik.handleSubmit}
            >
              <Grid item>
                <Typography textAlign="center" variant="h4" color="primary">
                  {t("TEXT.FORGOT_PASSWORD")}?
                </Typography>
                <Typography
                  textAlign="center"
                  fontSize={17}
                  sx={{ mt: 1, mb: 1 }}
                  variant="body1"
                >
                  {t("TEXT.REGISTERED_MESSAGE")}
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  formik={formik}
                  disabled={false}
                  label="Email"
                  name="email"
                  fullWidth
                />
                {formik.errors.email && formik.touched.email ? (
                  <ErrorMessage>{formik.errors.email}</ErrorMessage>
                ) : null}
              </Grid>
              <Grid item sx={{ display: "flex" }}>
                <Box>
                  <Button variant="outlined" onClick={() => navigate(LOGIN)}>
                    Login
                  </Button>
                </Box>
                <Box ml={2} width="100%">
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={!isFormValid}
                    fullWidth
                  >
                    {t("BUTTON.RESET_PASSWORD")}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Register;

export const SECTOR_ROUTE = `/sector`;

// Rota para retornar a configuração do setor por id do usuario
export const getSectorByUserIdRoute = (id: string) =>
  `${SECTOR_ROUTE}/byuser/${id}`;

// Rota para aplicar alteração do Setpoint do setor por id do usuario
export const sendSectorCommandRoute = (id: string) =>
  `${SECTOR_ROUTE}/sendcommand/${id}`;

// Rota para bloquear/desbloquear alterações em cada setor
export const sectorLockRoute = (sectorid: string) =>
  `${SECTOR_ROUTE}/changelock/${sectorid}`;

// Rota para resgatar os setores por id do setor
export const getSetorsByIdSectorRoute = (sectorid: string) =>
  `${SECTOR_ROUTE}/byid/${sectorid}`;

// Rota para remover setor
export const deleteSectorRoute = (sectorid: string) =>
  `${SECTOR_ROUTE}/${sectorid}`;

// Rota para remover controlador do setor
export const deleteCtrlFromSectorRoute = (sectorId: string, ctrlId: string) =>
  `${SECTOR_ROUTE}/removefromsector/${sectorId}/${ctrlId}`;

// Rota para editar uma variavel qualquer do payload setor
export const editSectorRoute = (sectorid: string) =>
  `${SECTOR_ROUTE}/${sectorid}`;

// Rota para sincronizar o setor após a edição
export const sincronizeSectorRoute = (sectorid: string) =>
  `${SECTOR_ROUTE}/sincronizesector/${sectorid}`;

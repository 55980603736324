import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
  MenuItem,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Checkbox,
  IconButton,
} from "@mui/material";
import {
  CheckCircle as CheckCircleIcon,
  ArrowLeft as ArrowLeftIcon,
  ArrowRight as ArrowRightIcon,
  Receipt as ReceiptIcon,
  Pix as PixIcon,
  Payment as PaymentIcon,
  HelpOutline as HelpOutlineIcon,
} from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from "yup";

import AvatarIcon from "../../../components/AvatarIcon";
import Spinner from "../../../components/Spinner";
import { red } from "@mui/material/colors";
import React from "react";
import {
  getIp,
  recurring,
  setInvoice,
  submitPlanFree,
} from "../../../services/data/subscriptions";
import { useNavigate, useParams } from "react-router-dom";
import { getLanguage } from "../../../translations/languages";
import { benefits, formatValueCents, planName } from "../parse";
import { useTranslation } from "react-i18next";
import { ThemeModeContext } from "../../../contexts/ThemeModeContext";
import ModalBenefits from "../Modal/ModalBenefits";
import { ToastContext } from "../../../contexts/ToastContext";
import { Select, TextField, yupRequiredString } from "../../../components/Form";
import { yupEmail, yupString } from "../../../components/Form/yup";
import Switch from "../../../components/Switch";
import ErrorMessage from "../../../components/ErrorMessage";
import {
  PLANS_AND_SUBSCRIPTIONS,
  PLANS_AVAILABLE,
} from "../../../Routes/constants";
import CepMask from "../../../components/Masks/CepMask";
import { getCep } from "../../../services/data/searchCep";
import CnpjMask from "../../../components/Masks/CnpjMask";
import CpfMask from "../../../components/Masks/CpfMast";
import PhoneMask from "../../../components/Masks/PhoneMask";
import { changeDateYear } from "../../Devices/DevicePage/parse";
import StepperCustom from "./SteppComponent";
import useIsMobile from "../../../hooks/useIsMobile";
import PlanTerms from "./PlanTerms";
import ModalQuestionRecurrence from "../Modal/ModalQuestionRecurrence";
import { getCouponByName } from "../../../services/data/coupon";

interface SubscriptionProps {
  device: any;
  plans: any;
  userInfo: any;
  state: string;
  neighborhood: string;
  street: string;
  city: string;
  handleChangeCity: (e: string) => void;
  handleChangeStreet: (e: string) => void;
  handleChangeNeighborhood: (e: string) => void;
  handleChangeState: (e: string) => void;
  setCity: (e: any) => void;
  setStreet: (e: any) => void;
  setNeighborhood: (e: any) => void;
  setState: (e: any) => void;
}

const Subscription = ({
  device,
  plans,
  userInfo,
  state,
  neighborhood,
  street,
  city,
  handleChangeCity,
  handleChangeStreet,
  handleChangeNeighborhood,
  handleChangeState,
  setCity,
  setStreet,
  setNeighborhood,
  setState,
}: SubscriptionProps) => {
  const { t } = useTranslation();
  const mobile = useIsMobile();
  const navigate = useNavigate();
  const { theme } = React.useContext(ThemeModeContext);
  const { toastError, toastSuccess } = React.useContext(ToastContext);
  const params = useParams();
  const language = getLanguage().code;
  const mac = params.mac ?? "";

  const [loading, setLoading] = React.useState(true);
  const [openModal, setOpenModal] = React.useState(false);
  const [legalPerson, setLegalPerson] = React.useState(
    (userInfo?.info?.cpf as string) === "" ? true : false
  );
  const [recurringSubscription, setRecurringSubscription] =
    React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [checked, setChecked] = React.useState(false);

  // Ações de abrir o modal de dúvidas sobre a 'Assinatura recorrente'
  const [openModalQuestion, setOpenModalQuestion] =
    React.useState<boolean>(false);
  const handleOpenModalQuestion = () => setOpenModalQuestion(true);
  const handleCloseModalQuestion = () => setOpenModalQuestion(false);

  // Ações do cupom
  const [couponIsValid, setCouponIsValid] = React.useState(false);
  const [couponValue, setCouponValue] = React.useState<number>(0);

  // Checkbox dos termos do Plano
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  // Alterar para pessoa jurídica
  const handleChangeLegalPerson = () => {
    setLegalPerson(!legalPerson);
  };

  // Alterar assinatura recorrente
  const handleChangeRecurringSubscription = () => {
    setRecurringSubscription(!recurringSubscription);
  };

  // Modal dos benefícios
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => setOpenModal(false);

  // Data atual + 5 dias
  const dueDate = changeDateYear(
    new Date(new Date().getTime() + 5 * 86400 * 1000).getTime()
  ) as string;

  const formik = useFormik({
    initialValues: {
      cpf: (userInfo?.info?.cpf as string) ?? "",
      cnpj: (userInfo?.info?.cnpj as string) ?? "",
      name: (userInfo?.name as string) ?? "",
      legalName: (userInfo?.info?.legalName as string) ?? "",
      email: (userInfo?.email as string) ?? "",
      ie: (userInfo?.info?.stateInsc as string) ?? "",
      phone: (userInfo?.phone as string) ?? "",
      coupon: "",
      cep: (userInfo?.info?.address?.cep as string) ?? "",
      billingType: "",
      number: (userInfo?.info?.address?.number as string) ?? "",
      complement: (userInfo?.info?.address?.complement as string) ?? "",
    },
    validationSchema: Yup.object().shape({
      cpf: legalPerson ? yupString : yupRequiredString,
      cnpj: legalPerson ? yupRequiredString : yupString,
      name: legalPerson ? yupString : yupRequiredString,
      legalName: legalPerson ? yupRequiredString : yupString,
      email: yupEmail,
      ie: yupString,
      phone: yupString,
      billingType: yupRequiredString,
      coupon: yupString,
      cep: yupRequiredString,
      number: yupRequiredString,
      complement: yupString,
    }),

    onSubmit: (values) => {
      const data = {
        client: {
          name: values.name,
          email: values.email,
          IE: values.ie,
          cpfCnpj: legalPerson
            ? values.cnpj.replace(".", "").replace("-", "").replace(".", "")
            : values.cpf.replace(".", "").replace("-", "").replace(".", ""),
          postalCode: values.cep,
          addressNumber: values.number,
          estado: state,
          cidade: city,
          bairro: neighborhood,
          rua: street,
          complemento: values.complement,
        },
        invoice: {
          value:
            (couponValue >= plans?.value_cents
              ? 0
              : couponValue !== undefined
              ? plans?.value_cents - couponValue
              : plans?.value_cents) / 100,
          dueDate: dueDate,
          description: planName(plans, language),
          billingType: values.billingType,
          plan_id: plans._id,
          mac: mac,
        },
      };

      // Se o switch da 'Assinatura recorrente' estiver ativo
      if (recurringSubscription === true) {
        // Resgatar o IP público da máquina
        getIp().then((res) => {
          const { data } = res;

          const recurringSubscriptionData = {
            client: {
              name: values.name,
              rua: street,
              bairro: neighborhood,
              complemento: values.complement,
              cpfCnpj: legalPerson
                ? values.cnpj.replace(".", "").replace("-", "").replace(".", "")
                : values.cpf.replace(".", "").replace("-", "").replace(".", ""),
            },
            remote_ip: data,
            mac: mac,
            plan_id: plans._id,
            IE: legalPerson ? values.ie : "ND",
            price:
              (couponValue >= plans?.value_cents - 500
                ? 500
                : couponValue !== undefined
                ? plans?.value_cents - couponValue
                : plans?.value_cents) / 100,
          };

          // Criar uma assinatura recorrente
          recurring(recurringSubscriptionData)
            .then((rec) => {
              const { data } = rec;
              // Abrir link da recorrencia
              if (data.url !== undefined) {
                window.open(data.url, "_blank");
              }
              toastSuccess(t("TOAST.SUCCESS.ADD_PLAN"));
              navigate(`${PLANS_AND_SUBSCRIPTIONS}/${mac}/${plans._id}`);
            })
            .catch(() => {
              toastError(t("TOAST.ERROR.ADD_PLAN"));
            });
        });

        // Se o valor do Plano for '0' altera diretamente o Plano para o selecionado
      } else if (data.invoice.value === 0) {
        submitPlanFree({
          mac: mac,
          plan: plans._id,
          status: plans.status,
        })
          .then(() => {
            toastSuccess(t("TOAST.SUCCESS.ADD_PLAN"));
            navigate(`${PLANS_AND_SUBSCRIPTIONS}/${mac}/${plans._id}`);
          })
          .catch(() => {
            toastError(t("TOAST.ERROR.ADD_PLAN"));
          });
      } else {
        // Gera um Invoice de pagamento com os dados preenchidos do formulário
        setInvoice(data)
          .then((res) => {
            if (res !== undefined) {
              const { data, status } = res;
              if (status === 400) {
                toastError(t("TOAST.ERROR.PENDING_PAYMENT_ALREADY"));
                navigate(`${PLANS_AND_SUBSCRIPTIONS}/${mac}/${plans._id}`);
              } else if (status === 500) {
                toastError(t(data.errors[0].description));
              } else {
                toastSuccess(t("TOAST.SUCCESS.ADD_PLAN"));
                // Abrir link do pagamento
                if (data.url !== undefined) {
                  window.open(data.url, "_blank");
                }
                navigate(`${PLANS_AND_SUBSCRIPTIONS}/${mac}/${plans._id}`);
              }
            }
          })
          .catch((err) => {
            toastError(err.response.data.errors[0].description);
          });
      }
    },
  });

  // Ação de mudar para o stepp anterior
  const handleBackStepp = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    // Resetar o input do Tipo de cobrança caso aperte no botão 'Anterior'
    formik.resetForm({
      values: {
        ...formik.values,
        billingType: "",
      },
    });
  };

  // Ação de mudar para o próximo stepp
  const handleNextStepp = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  // Buscar endereço via CEP
  const getAdress = async () => {
    await getCep(formik.values.cep)
      .then((res) => {
        const { data } = res;
        if (data.erro === true) {
          toastError(t("TOAST.ERROR.ZIP_CODE"));
        } else {
          toastSuccess(t("TOAST.SUCCESS.ZIP_CODE"));
          setCity(data.localidade);
          setStreet(data.logradouro);
          setNeighborhood(data.bairro);
          setState(data.uf);
        }
      })
      .catch(() => {
        toastError(t("TOAST.ERROR.ZIP_CODE"));
      });
  };

  // Formas de pagamento
  const billingTypes = ["BOLETO", "CREDIT_CARD", "PIX"];

  const options = billingTypes.map((value: any, i: number) => (
    <MenuItem value={value} key={i}>
      <List sx={{ p: 0 }}>
        {value === "BOLETO" ? (
          <ListItem>
            <ListItemIcon>
              <ReceiptIcon />
            </ListItemIcon>
            <ListItemText primary={t("TEXT.BILLET")} />
          </ListItem>
        ) : value === "CREDIT_CARD" && recurringSubscription === false ? (
          <ListItem>
            <ListItemIcon>
              <PaymentIcon />
            </ListItemIcon>
            <ListItemText
              primary={`${t("TEXT.CREDIT_CARD")} / ${t("TEXT.DEBIT")}`}
            />
          </ListItem>
        ) : value === "CREDIT_CARD" && recurringSubscription === true ? (
          <ListItem>
            <ListItemIcon>
              <PaymentIcon />
            </ListItemIcon>
            <ListItemText primary={t("TEXT.CREDIT_CARD")} />
          </ListItem>
        ) : (
          <ListItem>
            <ListItemIcon>
              <PixIcon />
            </ListItemIcon>
            <ListItemText primary="PIX" />
          </ListItem>
        )}
      </List>
    </MenuItem>
  ));

  // Checa se o cupom digitado é válido
  const checkCouponIsValid = () => {
    const coupon = formik.values.coupon.toUpperCase();
    getCouponByName(coupon)
      .then((res) => {
        const { data } = res;
        setCouponIsValid(true);
        setCouponValue(data.value);
        toastSuccess(t("TOAST.SUCCESS.VALID_COUPON"));
      })
      .catch(() => {
        toastError(t("TOAST.ERROR.VALID_COUPON"));
      });
  };

  // Loading do Spinner inical da página
  React.useEffect(() => {
    const fetchStatus = () => {
      if (plans && loading) {
        setLoading(false);
      }
    };
    fetchStatus();
    const statusInterval = setInterval(fetchStatus, 1000);
    return () => clearInterval(statusInterval);
  }, [plans, loading]);

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  // Nome do Dispositivo
  const deviceName = device && device?.name;

  // Valor do Plano (com adição de cupom)
  const planValue =
    couponValue >= plans?.value_cents && !recurringSubscription
      ? 0
      : couponValue >= plans?.value_cents - 500 &&
        recurringSubscription === true
      ? 500
      : couponValue !== undefined
      ? plans?.value_cents - couponValue
      : plans?.value_cents;

  // Validação do ducomento e contato
  const docIsValid = legalPerson
    ? formik.values.cnpj !== "" &&
      !formik.values.cnpj.includes("_") &&
      formik.values.legalName !== ""
    : formik.values.cpf !== "" &&
      !formik.values.cpf.includes("_") &&
      formik.values.name !== "";

  // Validação do endereço
  const addressIsValid =
    formik.values.number !== "" &&
    formik.values.cep !== "" &&
    !formik.values.cep.includes("_") &&
    (city !== "" || (userInfo?.info?.address?.city as string) !== "") &&
    street !== "" &&
    city !== "" &&
    state !== "" &&
    neighborhood !== "";

  // Validação da forma de pagamento
  const paymentIsValid =
    formik.values.billingType !== "" || recurringSubscription === true;

  // Validação geral do formulário
  const isFormValid = formik.values.billingType !== "" && formik.isValid;

  // Resetar o valor da forma de pagamento ao clicar no Switch de 'Assinatura recorrente'
  React.useEffect(() => {
    if (recurringSubscription === true) {
      formik.resetForm({
        values: {
          ...formik.values,
          billingType: activeStep < 3 ? "" : "CREDIT_CARD",
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep, recurringSubscription]);

  return (
    <Grid container sx={{ placeContent: "center" }}>
      <Box m={3.5} mt={0}>
        <Box position="sticky" top="90px">
          {plans && (
            <>
              <Card sx={{ mt: 0 }}>
                <CardContent>
                  <Box
                    display="flex"
                    justifyContent="center"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      pt: 3.5,
                      pb: 3.5,
                      pl: 5,
                      pr: 5,
                      borderRadius: 4,
                      mb: 2.4,
                      backgroundColor: red[100],
                    }}
                  >
                    <Avatar
                      sx={{
                        width: 120,
                        height: 120,
                        boxShadow: 3,
                        ml: 4,
                        mr: 4,
                      }}
                    >
                      <AvatarIcon
                        name={planName(plans, language)}
                        width={120}
                        height={120}
                        fontSize={70}
                        bgcolor={red[400]}
                      />
                    </Avatar>
                  </Box>
                  <Box mt={2} textAlign="center">
                    <Typography fontSize={25} fontWeight={500}>
                      {planName(plans, language)}
                    </Typography>
                    <Typography fontWeight={500} sx={{ mt: 0.5, mb: 0.5 }}>
                      {mac}
                    </Typography>
                  </Box>
                </CardContent>
                <Divider />
                <CardContent>
                  <Box display="flex" justifyContent="center">
                    <Typography fontWeight={700} fontSize={25}>
                      {planValue === 0
                        ? t("TEXT.FREE")
                        : formatValueCents(planValue.toString())}
                    </Typography>
                    <Typography
                      variant="caption"
                      fontSize="0.875rem"
                      fontWeight={500}
                      sx={{
                        ml: 1,
                        mt: 1.1,
                        color:
                          theme === "light" ? "rgb(108, 115, 127)" : "#FFFF",
                        fontFamily:
                          "Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
                      }}
                    >
                      {`/${plans.interval} ${t("TEXT.MONTHS")}`}
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="center" mt={1}>
                    <Button
                      sx={{ p: 0, fontSize: 12 }}
                      onClick={handleOpenModal}
                    >
                      {t("TEXT.SEE_ALL_BENEFITS")}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </>
          )}
        </Box>
      </Box>
      <Grid item xs={12} sm={12} md={12} lg={6}>
        {loading ? (
          <Box m="126px 0px">
            <Spinner />
          </Box>
        ) : (
          plans && (
            <Paper
              sx={{
                p: 3.5,
                pr:
                  activeStep === 0 && mobile
                    ? 1.5
                    : activeStep === 0 && !mobile
                    ? 3
                    : 3.5,
                borderRadius: 3,
                minHeight: 420,
                mb: 3,
              }}
            >
              <form onSubmit={formik.handleSubmit}>
                <Box>
                  <StepperCustom activeStep={activeStep} />
                  <Grid container direction="column" spacing={2}>
                    {activeStep > 0 && (
                      <Grid item sx={{ ml: 1 }}>
                        <Typography>{t("TEXT.DEVICE")}</Typography>
                        <Typography
                          variant="subtitle1"
                          fontWeight={500}
                          sx={{
                            borderBottom:
                              theme === "light"
                                ? "1px solid lightGray"
                                : "1px solid gray",
                          }}
                        >
                          {deviceName}
                        </Typography>
                      </Grid>
                    )}
                    {activeStep === 0 ? (
                      <>
                        <PlanTerms
                          planName={planName(plans, language)}
                          benefits={benefits(plans, language)}
                        />
                        <FormControlLabel
                          sx={{ mt: 2, pl: 3 }}
                          control={
                            <Checkbox
                              checked={checked}
                              onChange={handleChange}
                            />
                          }
                          label={t("TEXT.PLANS_TERMS_CHECKBOX")}
                        />
                      </>
                    ) : activeStep === 1 ? (
                      <>
                        <Grid item display="flex">
                          {legalPerson ? (
                            <Box width="100%">
                              <TextField
                                formik={formik}
                                disabled={false}
                                label="CNPJ"
                                name="cnpj"
                                fullWidth
                                InputProps={{
                                  inputComponent: CnpjMask,
                                }}
                              />
                              {formik.errors.cnpj && formik.touched.cnpj ? (
                                <ErrorMessage>
                                  {formik.errors.cnpj}
                                </ErrorMessage>
                              ) : null}
                            </Box>
                          ) : (
                            <Box width="100%">
                              <TextField
                                formik={formik}
                                disabled={false}
                                label="CPF"
                                name="cpf"
                                fullWidth
                                InputProps={{
                                  inputComponent: CpfMask,
                                }}
                              />
                              {formik.errors.cpf && formik.touched.cpf ? (
                                <ErrorMessage>{formik.errors.cpf}</ErrorMessage>
                              ) : null}
                            </Box>
                          )}

                          <Box alignSelf="center" ml={3}>
                            <FormControlLabel
                              sx={{ width: mobile ? 100 : 170 }}
                              control={
                                <Switch
                                  name="enable"
                                  checked={legalPerson}
                                  onChange={handleChangeLegalPerson}
                                  sx={{ mr: 1 }}
                                />
                              }
                              label={t("TEXT.LEGAL_PERSON")}
                            />
                          </Box>
                        </Grid>
                        {legalPerson ? (
                          <Grid item>
                            <TextField
                              formik={formik}
                              disabled={false}
                              label={t("TEXT.LEGAL_NAME")}
                              name="legalName"
                              fullWidth
                            />
                            {formik.errors.legalName &&
                            formik.touched.legalName ? (
                              <ErrorMessage>
                                {formik.errors.legalName}
                              </ErrorMessage>
                            ) : null}
                          </Grid>
                        ) : (
                          <Grid item>
                            <TextField
                              formik={formik}
                              disabled={false}
                              label={t("TEXT.NAME")}
                              name="name"
                              fullWidth
                            />
                            {formik.errors.name && formik.touched.name ? (
                              <ErrorMessage>{formik.errors.name}</ErrorMessage>
                            ) : null}
                          </Grid>
                        )}
                        {legalPerson && (
                          <Grid item>
                            <TextField
                              formik={formik}
                              disabled={false}
                              label={t("TEXT.STATE_INSC")}
                              name="ie"
                              fullWidth
                            />
                          </Grid>
                        )}
                        <Grid item>
                          <TextField
                            formik={formik}
                            disabled={false}
                            label="E-mail"
                            name="email"
                            fullWidth
                          />
                          {formik.errors.email && formik.touched.email ? (
                            <ErrorMessage>{formik.errors.email}</ErrorMessage>
                          ) : null}
                        </Grid>
                        <Grid item>
                          <TextField
                            formik={formik}
                            disabled={false}
                            label={t("TEXT.PHONE")}
                            name="phone"
                            fullWidth
                            InputProps={{
                              inputComponent: PhoneMask,
                            }}
                          />
                          {formik.errors.phone && formik.touched.phone ? (
                            <ErrorMessage>{formik.errors.phone}</ErrorMessage>
                          ) : null}
                        </Grid>
                      </>
                    ) : activeStep === 2 ? (
                      <>
                        <Grid item sx={{ display: "flex" }}>
                          <Box mr={2} width="100%">
                            <TextField
                              formik={formik}
                              label={t("TEXT.ZIP_CODE")}
                              name="cep"
                              fullWidth
                              InputProps={{
                                inputComponent: CepMask,
                              }}
                            />
                            {formik.errors.cep && formik.touched.cep ? (
                              <ErrorMessage>{formik.errors.cep}</ErrorMessage>
                            ) : null}
                          </Box>
                          <Box alignSelf="center">
                            <Button
                              variant="contained"
                              color="success"
                              onClick={getAdress}
                            >
                              {t("BUTTON.SEARCH")}
                            </Button>
                          </Box>
                        </Grid>
                        <Grid item>
                          <FormControl fullWidth>
                            <InputLabel>{t("TEXT.STREET")}</InputLabel>
                            <OutlinedInput
                              value={street}
                              onChange={(e) =>
                                handleChangeStreet(e.target.value)
                              }
                              startAdornment={
                                <InputAdornment position="start"></InputAdornment>
                              }
                              label={t("TEXT.STREET")}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item>
                          <FormControl fullWidth>
                            <InputLabel>{t("TEXT.CITY")}</InputLabel>
                            <OutlinedInput
                              value={city}
                              onChange={(e) => handleChangeCity(e.target.value)}
                              startAdornment={
                                <InputAdornment position="start"></InputAdornment>
                              }
                              label={t("TEXT.CITY")}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item>
                          <FormControl fullWidth>
                            <InputLabel>
                              {t("TEXT.STATE_USER_REGISTRATION")}
                            </InputLabel>
                            <OutlinedInput
                              value={state}
                              onChange={(e) =>
                                handleChangeState(e.target.value)
                              }
                              startAdornment={
                                <InputAdornment position="start"></InputAdornment>
                              }
                              label={t("TEXT.STATE_USER_REGISTRATION")}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item>
                          <FormControl fullWidth>
                            <InputLabel>{t("TEXT.NEIGHBORHOOD")}</InputLabel>
                            <OutlinedInput
                              value={neighborhood}
                              onChange={(e) =>
                                handleChangeNeighborhood(e.target.value)
                              }
                              startAdornment={
                                <InputAdornment position="start"></InputAdornment>
                              }
                              label={t("TEXT.NEIGHBORHOOD")}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item>
                          <TextField
                            formik={formik}
                            disabled={false}
                            label={t("TEXT.NUMBER")}
                            name="number"
                            fullWidth
                          />
                          {formik.errors.number && formik.touched.number ? (
                            <ErrorMessage>{formik.errors.number}</ErrorMessage>
                          ) : null}
                        </Grid>
                        <Grid item>
                          <TextField
                            formik={formik}
                            disabled={false}
                            label={t("TEXT.COMPLEMENT")}
                            name="complement"
                            fullWidth
                          />
                          {formik.errors.complement &&
                          formik.touched.complement ? (
                            <ErrorMessage>
                              {formik.errors.complement}
                            </ErrorMessage>
                          ) : null}
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item mt={1}>
                          <Box display="flex">
                            <FormControlLabel
                              sx={{ mb: 1, mr: 0.5 }}
                              control={
                                <Switch
                                  name="recurringSubscription"
                                  checked={recurringSubscription}
                                  onChange={handleChangeRecurringSubscription}
                                  sx={{ ml: 2, mr: 1 }}
                                />
                              }
                              label={t("TEXT.RECURRING_SUBSCRIPTION")}
                            />
                            <IconButton
                              color="primary"
                              onClick={handleOpenModalQuestion}
                              sx={{ mt: -1 }}
                            >
                              <HelpOutlineIcon color="info" />
                            </IconButton>
                          </Box>
                        </Grid>
                        <Grid item>
                          <Select
                            formik={formik}
                            label={t("TEXT.BILLING_TYPE")}
                            name="billingType"
                            options={options}
                            value={
                              recurringSubscription === true
                                ? "CREDIT_CARD"
                                : formik.values.billingType
                            }
                            disabled={
                              recurringSubscription === true ? true : false
                            }
                            sx={
                              formik.values.billingType !== ""
                                ? {
                                    "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                                      {
                                        p: 0.5,
                                      },
                                    "& .css-qiwgdb": {
                                      p: 0.5,
                                    },
                                  }
                                : {}
                            }
                          />
                        </Grid>
                        <Grid item display="flex">
                          <TextField
                            formik={formik}
                            disabled={false}
                            label={t("TEXT.COUPON_MESSAGE")}
                            name="coupon"
                            fullWidth
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                          />
                          {couponIsValid === true && (
                            <CheckCircleIcon
                              color="success"
                              fontSize="large"
                              sx={{ alignSelf: "center", ml: 1 }}
                            />
                          )}
                          <Box alignSelf="center" ml={1}>
                            <Button
                              color="success"
                              variant="contained"
                              disabled={formik.values.coupon === ""}
                              onClick={checkCouponIsValid}
                            >
                              {t("BUTTON.APPLY")}
                            </Button>
                          </Box>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Box>
                <Box
                  mt={3}
                  display="flex"
                  justifyContent="space-between"
                  pl={3}
                  pr={3}
                >
                  {activeStep === 0 ? (
                    <Button
                      onClick={() => navigate(`${PLANS_AVAILABLE}/${mac}`)}
                      variant="outlined"
                      size={mobile ? "small" : "medium"}
                    >
                      {t("BUTTON.CANCEL")}
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      startIcon={<ArrowLeftIcon />}
                      onClick={handleBackStepp}
                      size={mobile ? "small" : "medium"}
                    >
                      {t("TEXT.PREVIOUS")}
                    </Button>
                  )}
                  {activeStep === 3 ? (
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={!isFormValid}
                      size={mobile ? "small" : "medium"}
                    >
                      {t("BUTTON.CONCLUDE")}
                    </Button>
                  ) : (
                    <Button
                      size={mobile ? "small" : "medium"}
                      disabled={
                        activeStep === 0
                          ? !checked
                          : activeStep === 1
                          ? !docIsValid
                          : activeStep === 2
                          ? !addressIsValid
                          : activeStep === 3
                          ? !paymentIsValid
                          : isFormValid
                      }
                      variant="contained"
                      onClick={handleNextStepp}
                      endIcon={<ArrowRightIcon />}
                    >
                      {t("TEXT.NEXT")}
                    </Button>
                  )}
                </Box>
              </form>
            </Paper>
          )
        )}
      </Grid>
      {plans && (
        <ModalBenefits
          benefits={benefits(plans, language)}
          handleClose={handleCloseModal}
          handleOpen={openModal}
        />
      )}
      <ModalQuestionRecurrence
        handleOpen={openModalQuestion}
        handleClose={handleCloseModalQuestion}
        plan={planName(plans, language)}
      />
    </Grid>
  );
};

export default Subscription;

import {
  Box,
  Card as MuiCard,
  CardContent,
  IconButton,
  Tooltip,
  Typography,
  Alert,
  Button,
} from "@mui/material";
import {
  Replay as ReplayIcon,
  AddToPhotos as AddToPhotosIcon,
  FileDownload as FileDownloadIcon,
} from "@mui/icons-material";

import { useTranslation } from "react-i18next";
import useIsMobile from "../../hooks/useIsMobile";
import Card from "./Card";
import React from "react";
import {
  getSectorByUserId,
  getSetorsByIdSector,
} from "../../services/data/sector";
import { AuthContext } from "../../contexts/AuthContext";
import { api } from "../../services/api";
import { TOKEN_KEY } from "../../auth/constants";
import { MqttContext } from "../../contexts/MqttContext";
import { CurrentMqttSubsContext } from "../../contexts/CurrentMqttSubsContext";
import { getModelos } from "../../services/data/modelo";
import { updateCtrl } from "../../config";
import Spinner from "../../components/Spinner";
import {
  getConfig,
  getDashboardMacs,
  getDevice,
} from "../../services/data/arcsys";
import { SectorsContext } from "../../contexts/SectorContext";
import ModalAdd from "./Modal/Add";
import ModalAddedDevice from "./Modal/AddedDevice";
import ModalSaveSync from "./Modal/Question/SaveSync";
import { ToastContext } from "../../contexts/ToastContext";
import { printUserManualAsPDF } from "../Scenarios/parse";
import { fileURL } from "./parse";
import { ArcsysContext } from "../../contexts/ArcsysProviderContext";

let sectorList: any[] = [];
let data: any[] = [];
let deviceUsers: any[] = [];

interface SectorDevice {
  id: string;
  mac: string;
  ctrls: any;
  sectorCtrl: any;
  avg_devices?: any;
  device_inverter_off?: boolean;
}

const Sectors = () => {
  const { t } = useTranslation();
  const mobile = useIsMobile();
  const { authenticated, token, client, userId } =
    React.useContext(AuthContext);
  const { macsToSubscribe, setMacsToSubscribe } =
    React.useContext(ArcsysContext);
  const { mqtt } = React.useContext(MqttContext);
  const { sectors, setSectors } = React.useContext(SectorsContext);
  const { toastSuccess } = React.useContext(ToastContext);
  const { currentMqtt, setCurrentMqtt } = React.useContext(
    CurrentMqttSubsContext
  );
  const [loading, setLoading] = React.useState(true);
  const [offlineTimeCtrl, setOfflineTimeCtrl] = React.useState<number>();
  const [sectorEdited, setSectorEdited] = React.useState<boolean>(false);

  // Ações de sincronizar os Dispositivos do setor criado
  let intervalIdSubmit: NodeJS.Timer;
  const [submittingAdd, setSubmittingAdd] = React.useState(false);
  const [addedDeviceSync, setAddedDeviceSync] = React.useState<any>();
  const [addedDevice, setAddedDevice] = React.useState<any>();
  const isIntervalActiveSubmit = React.useRef(false);
  const handleCloseModaladdedDevice = () => {
    setSubmittingAdd(false);
    setAddedDeviceSync(undefined);
  };
  const checkContentStatusSubmit = async (id: string) => {
    try {
      // Resgatar os setores por id do setor
      getSetorsByIdSector(id).then((res) => {
        const { data } = res;
        setAddedDeviceSync(data);
        // Se comand_control.running for igual a finished, cancelar o interval
        if (data.comand_control.running === "finished") {
          // Varrer a lista de devices do setor e verificar o last_exec_status
          const devicesLastStatus = data.devices.filter(
            (f: any) => f.last_exec_status === null
          );
          // Se todos forem diferentes de 'null', cancelar interval
          if (devicesLastStatus.length === 0) {
            clearInterval(intervalIdSubmit);
            isIntervalActiveSubmit.current = false;
            setSectorEdited(true);
            toastSuccess(t("TOAST.SUCCESS.CREATE_SECTOR"));
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  const startIntervalSubmit = (id: string) => {
    if (!isIntervalActiveSubmit.current) {
      intervalIdSubmit = setInterval(() => checkContentStatusSubmit(id), 5000);
      setSubmittingAdd(true);
      isIntervalActiveSubmit.current = true;
    }
  };

  // Ações de abrir o modal de sincronizar
  const [openModalSaveSync, setOpenModalSaveSync] =
    React.useState<boolean>(false);
  const [modalSyncValue, setModalSyncValue] = React.useState<string>();
  const handleOpenModalSaveSync = (id: string) => {
    setOpenModalSaveSync(true);
    setModalSyncValue(id);
  };
  const handleCloseModalSaveSync = () => {
    setOpenModalSaveSync(false);
  };

  // Retornar a configuração do setor por id do usuario
  React.useEffect(() => {
    if (authenticated) {
      const fetchStatus = () => {
        if (sectors.length === 0) {
          if (token !== undefined) {
            localStorage.setItem(TOKEN_KEY, token);
          }
          api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);
          getSectorByUserId(userId).then((res) => {
            const { data } = res;
            setSectors(data);

            if (data.length === 0) {
              setLoading(false);
            }
          });
        }

        const devices = sectors.map((m: any) => {
          return m.devices;
        });

        const macs: string[] = devices.flat().map((m: any) => {
          return m.ctrlId?.split("/")[0];
        });

        // Remover endereços MACs duplicados
        const uniqueMacs = macs.filter(
          (item, index) => macs.indexOf(item) === index
        );

        // Resgata os dispositivos do usuário por endereço MAC
        uniqueMacs.forEach(async (mac: any) => {
          await getDevice(mac).then((d) => {
            const { data } = d;
            deviceUsers.push(data);
          });
        });
      };
      fetchStatus();
      const statusInterval = setInterval(fetchStatus, 10000000);
      return () => clearInterval(statusInterval);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated, token, userId]);

  // Atualizar os dados do Setor após Aplicar setpoint
  React.useEffect(() => {
    if (sectorEdited) {
      getSectorByUserId(userId).then((res) => {
        const { data } = res;
        setSectors(data);

        const devices = data.map((m: any) => {
          return m.devices;
        });

        const macs: string[] = devices.flat().map((m: any) => {
          return m.ctrlId?.split("/")[0];
        });

        // Remover endereços MACs duplicados
        const uniqueMacs = macs.filter(
          (item, index) => macs.indexOf(item) === index
        );

        // Resgata os dispositivos do usuário por endereço MAC
        uniqueMacs.forEach(async (mac: any) => {
          let found = false;
          deviceUsers.forEach((fe) => {
            if (fe.mac === mac) {
              found = true;
            }
          });

          if (!found) {
            await getDevice(mac).then((d) => {
              const { data } = d;
              deviceUsers.push(data);
            });
          }
        });

        if (data.length === 0) {
          setLoading(false);
        }
      });
      setSectorEdited(false);
    }
  }, [sectorEdited, setSectors, userId]);

  React.useEffect(() => {
    const fetchStatus = () => {
      if (authenticated) {
        if (token !== undefined) {
          localStorage.setItem(TOKEN_KEY, token);
        }
        api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);
        // Se a key 'ageonRef' do localStorage for indefinida:
        // Resgata os modelos JSON conforme a linguagem selecionada
        if (localStorage.ageonRef === undefined) {
          getModelos().then((res) => {
            if (localStorage.lang === "pt") {
              localStorage.setItem(
                "ageonRef",
                JSON.stringify(res.data.model["pt-br"])
              );
            } else if (localStorage.lang === "en") {
              localStorage.setItem(
                "ageonRef",
                JSON.stringify(res.data.model.en)
              );
            } else {
              localStorage.setItem(
                "ageonRef",
                JSON.stringify(res.data.model.es)
              );
            }
            updateCtrl(localStorage.ageonRef);
          });
        } else {
          updateCtrl(localStorage.ageonRef);
        }
      }
    };
    fetchStatus();
    const statusInterval = setInterval(fetchStatus, 10000000);
    return () => clearInterval(statusInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated]);

  React.useEffect(() => {
    const fetchStatus = () => {
      if (macsToSubscribe?.length === 0) {
        // Rota para resgatar todos os dispositivos MACs no Dashboard
        getDashboardMacs().then((res) => {
          const { data } = res;
          setMacsToSubscribe(data);
        });
      }
    };
    fetchStatus();
    const intervalId = setInterval(fetchStatus, 10000000000);
    return () => clearInterval(intervalId);
  }, [macsToSubscribe?.length, setMacsToSubscribe]);

  // Dar subscribe nos arcsys do usuário
  React.useEffect(() => {
    const fetchStatus = () => {
      const { connected } = client ?? {};

      let subscribed_devices: string[] = [];

      if (connected === true) {
        macsToSubscribe?.forEach(({ mac }) => {
          if (mac) {
            subscribed_devices.push(mac);

            if (currentMqtt?.includes(`${mac}/EVT/#`)) return;
            client?.subscribe(`${mac}/EVT/#`);
            setCurrentMqtt((prev) =>
              prev ? `${prev},${mac}/EVT/#` : `${mac}/EVT/#`
            );
          }
        });

        currentMqtt?.split(",").forEach((subscription) => {
          const mac = subscription.split("/")[0];
          if (mac.length && !subscribed_devices.includes(mac)) {
            client?.unsubscribe(subscription);

            // Remove unsubscribed device from currentMqtt
            setCurrentMqtt((prev) => {
              if (!prev) return prev;
              const subscriptions = prev.split(",");
              const filtered = subscriptions.filter(
                (sub) => sub !== subscription
              );
              return filtered.join(",");
            });
          }
        });
      }
    };

    fetchStatus();
    const intervalId = setInterval(fetchStatus, 1000);
    return () => clearInterval(intervalId);
  }, [client, currentMqtt, macsToSubscribe, setCurrentMqtt]);

  React.useEffect(() => {
    const fetchStatus = () => {
      // Requisição get contendo o valor configurado do tempo offline do arcsys
      getConfig().then((res) => {
        setOfflineTimeCtrl(res.data.ctrlInactiveTime);
      });
    };
    fetchStatus();
    // Dar um get somente uma vez nessa requisição
    const statusInterval = setInterval(fetchStatus, 10000000);
    return () => clearInterval(statusInterval);
  }, []);

  // Filtra os dados que vem do MQTT
  React.useEffect(() => {
    const fetchStatus = () => {
      if (mqtt !== undefined) {
        sectorList = sectors.map((sector) => {
          let this_sector: SectorDevice = {
            ...sector,
            id: sector._id,
            mac: sector.reference?.ctrlId.split("/")[0],
            sectorCtrl: null,
            ctrls: [],
          };
          // Adicionar dispositivo de referência de controle de setor se corresponder ao endereço MAC
          const sectorCtrlData = mqtt[this_sector.mac];
          if (sectorCtrlData) {
            this_sector.sectorCtrl = Object.entries(sectorCtrlData)
              .map(([ctrl, payload]) => ({
                mac: this_sector.mac,
                ctrl,
                payload,
              }))
              .find((f) => f.ctrl === sector.reference?.ctrlId.split("/")[1]);
          }
          // Adicione dispositivos ao setor
          let num_devices_avg: any = {};
          let avg_devices: any = {};
          let device_inverter_off = false;
          sector.devices.forEach((device: any) => {
            const deviceMAC = device.ctrlId.split("/")[0];
            const deviceCtrls = mqtt[deviceMAC];
            let found = false;
            if (deviceCtrls) {
              Object.entries(deviceCtrls).forEach(([ctrl, payload]) => {
                if (ctrl === device.ctrlId.split("/")[1]) {
                  found = true;
                  this_sector.ctrls.push({
                    mac: deviceMAC,
                    ctrl,
                    payload,
                    online:
                      (payload as any)?.lastMsg + offlineTimeCtrl > Date.now(),
                  });
                  if (
                    (payload as any)?.medidas &&
                    +(payload as any)?.end !== 36
                  ) {
                    if (+(payload as any)?.estado === 1) {
                      device_inverter_off = true;
                    }
                    for (let index = 1; index < 6; index++) {
                      if (+(payload as any)?.medidas[`m${index}`] !== 32767) {
                        if (num_devices_avg[`m${index}`] === undefined) {
                          num_devices_avg[`m${index}`] = 1;
                        } else {
                          num_devices_avg[`m${index}`] += 1;
                        }
                        if (avg_devices[`m${index}`] === undefined) {
                          avg_devices[`m${index}`] = +(payload as any)?.medidas[
                            `m${index}`
                          ];
                        } else {
                          avg_devices[`m${index}`] += +(payload as any)
                            ?.medidas[`m${index}`];
                        }
                      }
                    }
                  }
                }
              });
            }
            if (!found) {
              this_sector.ctrls.push({
                mac: deviceMAC,
                ctrl: device.ctrlId.split("/")[1],
                payload: null,
                online: false,
              });
            }
          });
          for (let index = 1; index < 6; index++) {
            if (
              !!num_devices_avg[`m${index}`] &&
              num_devices_avg[`m${index}`] > 0
            ) {
              avg_devices[`m${index}`] /= num_devices_avg[`m${index}`];
            } else {
              avg_devices[`m${index}`] = 32767;
            }
          }
          this_sector.device_inverter_off = device_inverter_off;
          this_sector.avg_devices = avg_devices;
          return this_sector;
        });

        data = [];
        sectorList.forEach((f) => {
          data.push(f);
        });
      }
    };
    fetchStatus();
    const statusInterval = setInterval(fetchStatus, 3000);
    return () => clearInterval(statusInterval);
  }, [mqtt, offlineTimeCtrl, sectors]);

  React.useEffect(() => {
    const fetchStatus = () => {
      // Apenas na primeira vez
      if (data.length === 0) {
        sectors.forEach((v) => {
          data.push(v);
        });
      }
    };
    fetchStatus();
  }, [sectors]);

  // Ações do modal de adicionar
  const [openModalAdd, setOpenModalAdd] = React.useState(false);
  const handleOpenModalAdd = () => {
    setOpenModalAdd(true);
  };
  const handleCloseModalAdd = () => {
    setOpenModalAdd(false);
  };

  // Loading do Spinner inicial da página
  React.useEffect(() => {
    const fetchStatus = () => {
      if (data && data?.length !== 0 && loading) {
        setLoading(false);
      }
    };
    fetchStatus();
    const statusInterval = setInterval(fetchStatus, 1000);
    return () => clearInterval(statusInterval);
  }, [loading]);

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 10000);
  }, []);

  // Ação de atualizar tabela
  const reloadTable = () => {
    setLoading(true);
    getSectorByUserId(userId).then((res) => {
      const { data } = res;
      setSectors(data);
      if (data.length === 0) {
        setLoading(false);
      }
    });
  };

  return (
    <Box display="grid">
      <Box overflow="auto" whiteSpace="nowrap">
        <Box display="flex" justifyContent="center">
          <MuiCard elevation={3} sx={{ width: 1500, p: mobile ? 0 : 1, mb: 2 }}>
            <CardContent sx={{ p: mobile ? 0 : 1 }}>
              <Box display="flex" justifyContent="space-between" ml={2}>
                <Typography
                  fontSize={mobile ? 22 : 25}
                  gutterBottom
                  sx={{ mt: mobile ? 1 : 0, mb: 0 }}
                >
                  {t("TEXT.SECTORS")}
                </Typography>
                <Box display="flex">
                  <Box mt={mobile ? 0.4 : 0.7} mr={mobile ? 0 : 2}>
                    {mobile ? (
                      <IconButton
                        color="warning"
                        onClick={() =>
                          printUserManualAsPDF(
                            `${t("TEXT.USER_MANUAL")}.pdf`,
                            fileURL
                          )
                        }
                        sx={{ mt: 0.5, mr: 0.5 }}
                      >
                        <FileDownloadIcon />
                      </IconButton>
                    ) : (
                      <Button
                        size="small"
                        color="warning"
                        startIcon={<FileDownloadIcon />}
                        onClick={() =>
                          printUserManualAsPDF(
                            `${t("TEXT.USER_MANUAL")}.pdf`,
                            fileURL
                          )
                        }
                        sx={{ fontSize: 13, fontWeight: 600 }}
                      >
                        {t("TEXT.USER_MANUAL")}
                      </Button>
                    )}
                  </Box>
                  <Box display="flex">
                    {mobile ? (
                      <IconButton
                        color="warning"
                        onClick={handleOpenModalAdd}
                        sx={{ mt: 0.5, mr: 0.5 }}
                      >
                        <AddToPhotosIcon />
                      </IconButton>
                    ) : (
                      <Button
                        size="small"
                        color="warning"
                        startIcon={<AddToPhotosIcon />}
                        onClick={handleOpenModalAdd}
                        sx={{ fontSize: 13, fontWeight: 600, mr: 0.5 }}
                      >
                        {t("BUTTON.ADD")}
                      </Button>
                    )}
                    <Tooltip title={t("TEXT.UPDATE") as string}>
                      <IconButton
                        color="warning"
                        onClick={reloadTable}
                        sx={{ mt: mobile ? 0.5 : 0 }}
                      >
                        <ReplayIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              </Box>
              <MuiCard elevation={0}>
                <CardContent>
                  {loading ? (
                    <Box m={mobile ? "155px 0px" : "170px 0px"}>
                      <Spinner />
                    </Box>
                  ) : data?.length !== 0 ? (
                    <Card
                      data={data}
                      setSectorEdited={setSectorEdited}
                      deviceUsers={Array.from(
                        new Map(
                          deviceUsers.map((item) => [item._id, item])
                        ).values()
                      )}
                    />
                  ) : (
                    <Box display="flex" justifyContent="center" pt={8} pb={4}>
                      <Box />
                      <Alert
                        severity="info"
                        sx={{
                          textAlign: "center",
                          placeContent: "center",
                          width: 310,
                        }}
                      >
                        {t("TEXT.EMPTY_SECTOR")}
                      </Alert>
                      <Box />
                    </Box>
                  )}
                </CardContent>
              </MuiCard>
            </CardContent>
          </MuiCard>
        </Box>
      </Box>
      {openModalAdd && (
        <ModalAdd
          handleOpenModalSaveSync={handleOpenModalSaveSync}
          handleOpen={openModalAdd}
          handleClose={handleCloseModalAdd}
          setAddedDevice={setAddedDevice}
          deviceUsers={Array.from(
            new Map(deviceUsers.map((item) => [item._id, item])).values()
          )}
        />
      )}
      <ModalAddedDevice
        data={addedDeviceSync}
        handleClose={handleCloseModaladdedDevice}
        handleOpen={submittingAdd}
        checked={false}
        deviceUsers={Array.from(
          new Map(deviceUsers.map((item) => [item._id, item])).values()
        )}
      />
      <ModalSaveSync
        handleClose={handleCloseModalSaveSync}
        handleOpen={openModalSaveSync}
        isSubmitting={true}
        modalSyncValue={modalSyncValue}
        startIntervalSubmit={startIntervalSubmit}
        setSectorEdited={setSectorEdited}
        dataEdited={addedDevice}
      />
    </Box>
  );
};

export default Sectors;

import { useTranslation } from "react-i18next";

import {
  Avatar,
  Box,
  Button,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { HelpOutline as HelpOutlineIcon } from "@mui/icons-material";
import Modal from "../../Devices/Modal";
import { red } from "@mui/material/colors";
import useIsMobile from "../../../hooks/useIsMobile";
import React from "react";

interface ModalTelegramHelpProps {
  handleOpen: boolean;
  handleClose: () => void;
}

const ModalTelegramHelp = ({
  handleOpen,
  handleClose,
}: ModalTelegramHelpProps) => {
  const { t } = useTranslation();
  const mobile = useIsMobile();

  const steps = [
    {
      label: t("TEXT.ACCESS_TELEGRAM"),
      description: t("TEXT.ACCESS_TELEGRAM_MESSAGE"),
    },
    {
      label: t("TEXT.INSTRUCTIONS"),
      description: t("TEXT.TELEGRAM_INSTRUCTIONS"),
    },
  ];

  return (
    <Modal
      open={handleOpen}
      handleClose={handleClose}
      width={mobile ? 340 : 460}
    >
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 3.5,
          pb: 3.5,
          borderRadius: 4,
          mb: 2,
          backgroundColor: red[100],
        }}
        elevation={0}
      >
        <Avatar sx={{ width: 85, height: 85, bgcolor: red[400], boxShadow: 3 }}>
          <HelpOutlineIcon sx={{ fontSize: 65 }} />
        </Avatar>
      </Paper>
      <Typography fontSize={19} fontWeight={700} mb={2}>
        {t("TEXT.TELEGRAM_BOT_TITLE")}
      </Typography>
      <Box>
        <Stepper orientation="vertical">
          {steps.map((step, index) => (
            <Step active key={step.label}>
              <StepLabel>{step.label}</StepLabel>
              <StepContent>
                <Typography>
                  {step.description}
                  {index === 0 && (
                    <>
                      <strong style={{ marginLeft: "4px" }}>
                        <a
                          href="https://t.me/ageon_cloud_bot"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {t("TEXT.TELEGRAM_BOT_LINK")}
                        </a>
                      </strong>
                      .
                    </>
                  )}
                </Typography>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Box mt={2} textAlign="center">
        <Button
          onClick={handleClose}
          sx={{
            backgroundColor: red[50],
            mt: 1,
            borderRadius: 2,
            fontSize: 12,
            p: 1,
            pl: 3,
            pr: 3,
            color: "black",
          }}
        >
          {t("BUTTON.RETURN")}
        </Button>
      </Box>
    </Modal>
  );
};

export default ModalTelegramHelp;

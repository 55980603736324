import { TOKEN_KEY } from "../../auth/constants";
import { api } from "../api";
import {
  GET_TOKEN_TELEGRAM_ROUTE,
  USER_ROUTE,
  changePasswordRoute,
  deleteUserRoute,
  getNumbersOfUsersRoute,
  getUserRoute,
} from "../routes/user";

// Resgatar a lista de usuários da conta
export const getUsers = () => api.get<any>(USER_ROUTE);

let isRequestPendingGetUserById = false;
// Resgatar os dados do usuário
export const getUserById = async (addr: number) => {
  if (isRequestPendingGetUserById) {
    while (isRequestPendingGetUserById) {
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  }
  isRequestPendingGetUserById = true;
  try {
    const response = await api.get(getUserRoute(addr));
    return response;
  } catch (error) {
    const err = (error as any).response.status;
    if (err === 401) {
      localStorage.setItem(TOKEN_KEY, "");
      window.location.replace("login");
    }
  } finally {
    isRequestPendingGetUserById = false;
  }
};

// Editar os dados do usuário
export const editUserById = (addr: number, data: any) =>
  api.put<void>(getUserRoute(addr), data);

// Editar a senha do usuário logado
export const changePassword = (addr: number, data: any) =>
  api.put<void>(changePasswordRoute(addr), data);

// Resgatar o Token Telegram
export const getTokenTelegram = () => api.get<any>(GET_TOKEN_TELEGRAM_ROUTE);

// Rota para resgatar a quantidade de usuários que irão ser renderizados
export const getNumbersOfUsers = (
  page: number,
  general: string,
  rowsPerPage: number,
  asAdmin: number,
  queryType: string
) =>
  api.get<any>(
    getNumbersOfUsersRoute(page, general, rowsPerPage, asAdmin, queryType)
  );

// Rota para excluir usuário
export const deleteUser = (addr: number) =>
  api.delete<any>(deleteUserRoute(addr));

import {
  Box,
  Card,
  CardContent,
  Chip,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  IconButton,
  InputBase,
  Avatar,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import {
  Add as AddIcon,
  ListAlt as ListAltIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  CardGiftcard as CardGiftcardIcon,
  AddCircleOutline as AddCircleOutlineIcon,
  AttachMoney as AttachMoneyIcon,
  Search as SearchIcon,
  Clear as ClearIcon,
  Replay as ReplayIcon,
  RunningWithErrors as RunningWithErrorsIcon,
  FileDownloadOutlined as FileDownloadOutlinedIcon,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import useIsMobile, { useIsMediumPage } from "../../hooks/useIsMobile";
import React from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { TOKEN_KEY } from "../../auth/constants";
import { api } from "../../services/api";
import Spinner from "../../components/Spinner";
import { ThemeModeContext } from "../../contexts/ThemeModeContext";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import AvatarIcon from "../../components/AvatarIcon";
import { blue, green, red } from "@mui/material/colors";
import { getLanguage } from "../../translations/languages";
import { getPlans, getPlansToExpire } from "../../services/data/subscriptions";
import { useNavigate } from "react-router-dom";
import {
  PLANS_AND_SUBSCRIPTIONS,
  PLANS_AVAILABLE,
} from "../../Routes/constants";
import {
  benefits,
  convertToDatePaymentPending,
  convertToDateValue,
  enExpiresDate,
  queryTypeOptions,
  selectNameByTranslations,
} from "./parse";
import ModalAddPlan from "./Modal/ModalAddPlan";
import { ToastContext } from "../../contexts/ToastContext";
import AdminArea from "./AdminArea";
import ModalSignatureReport from "./Modal/ModalSignatureReport";
import DataGridServerSide from "../../components/DataGridServerSide";

const PlansAndSubscriptions = () => {
  const { t } = useTranslation();
  const mobile = useIsMobile();
  const smallWindow = window.innerWidth < 1200;
  const navigate = useNavigate();
  const mediumPage = useIsMediumPage();
  const { userArcsys, token, authenticated, userId } =
    React.useContext(AuthContext);
  const { toastError } = React.useContext(ToastContext);
  const language = getLanguage().code;
  const { theme } = React.useContext(ThemeModeContext);
  const [devicesList, setDevicesList] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [planOption, setPlanOption] = React.useState(0);
  const [searchedDevice, setSearchedDevice] = React.useState<string>("");
  const [searchedClicked, setSearchedClicked] = React.useState<string>("");
  const [searchedCleaned, setSearchedCleaned] = React.useState<boolean>(false);
  const [isSearched, setIsSearched] = React.useState<boolean>(false);
  const [queryType, setQueryType] = React.useState("device");
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [totalPages, setTotalPages] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(50);
  const [devicePlansList, setDevicePlansList] = React.useState<any>([]);
  const [plansToExpire, setPlansToExpire] = React.useState<number>(0);

  // Selecionar o tipo de pesquisa
  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQueryType(event.target.value);
  };

  // Checar se o usuário é Admin
  const userIsAdmin = userArcsys?.role === "ADMIN";

  // Ações de adicionar um plano a um endereço MAC
  const [openModalAddPlan, setOpenModalAddPlan] = React.useState(false);
  const [modalAddPlanValues, setModalAddPlanValues] = React.useState<any>();
  const [planEdited, setPlanEdited] = React.useState<boolean>(false);

  // Barra de pesquisa
  const handleSearch = (e: any) => {
    setSearchedDevice(e);
    setIsSearched(true);
  };

  // Limpar barra de pesquisa
  const handleClearSearch = () => {
    (document.getElementById("myInput") as any).value = "";
    setSearchedDevice("");
    setSearchedClicked("");
    setSearchedCleaned(true);
  };

  // Rota que retorna o número de planos à vencer
  React.useEffect(() => {
    if (authenticated) {
      if (token !== undefined) {
        localStorage.setItem(TOKEN_KEY, token);
      }
      api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);
      const fetchStatus = () => {
        getPlansToExpire().then((res) => {
          if (res?.data) {
            setPlansToExpire(res.data.qtn);
          }
        });
      };
      fetchStatus();
      const statusInterval = setInterval(fetchStatus, 10000000);
      return () => clearInterval(statusInterval);
    }
  }, [authenticated, token]);

  React.useEffect(() => {
    if (authenticated) {
      if (token !== undefined) {
        localStorage.setItem(TOKEN_KEY, token);
      }
      api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);
      const fetchStatus = () => {
        // Resgatar apenas os Dispositivos com pending=false
        if (
          devicePlansList.length === 0 ||
          searchedDevice !== "" ||
          searchedCleaned === true ||
          pageNumber ||
          queryType ||
          isSearched
        ) {
          getPlans(
            pageNumber,
            rowsPerPage,
            searchedDevice,
            queryTypeOptions(planOption, searchedDevice, queryType),
            "",
            0
          ).then((res) => {
            if (res?.data) {
              setDevicesList(res.data.data);
              setTotalPages(res.data.total);
              if (res.data?.data.length === 0) {
                setLoading(false);
              }
            }
          });
        } else {
          const deviceFilter = devicePlansList.filter(
            (f: any) => f.subscriptions.length > 0
          );

          setDevicesList(
            // eslint-disable-next-line array-callback-return
            deviceFilter.map((m: any) => {
              const filterPermissions = m?.users.filter(
                (f: any) => f.pending === false && f.id === +userId
              );
              if (filterPermissions.length !== 0) {
                return m;
              }
            })
          );
        }
      };
      fetchStatus();
      const statusInterval = setInterval(fetchStatus, 10000000);
      return () => clearInterval(statusInterval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    authenticated,
    token,
    searchedDevice,
    setDevicePlansList,
    searchedCleaned,
    rowsPerPage,
    pageNumber,
    planOption,
    userIsAdmin,
    isSearched,
    userId,
  ]);

  // Se o Plano for editado resgata novamente a lista de Dispositivos e atualiza o useState
  React.useEffect(() => {
    if (planEdited) {
      // Resgatar apenas os Dispositivos com pending=false
      getPlans(
        pageNumber,
        rowsPerPage,
        searchedDevice,
        queryTypeOptions(planOption, searchedDevice, queryType),
        "",
        0
      ).then((res) => {
        if (res?.data) {
          setDevicesList(res.data.data);
          setTotalPages(res.data.total);
        }
      });
      setPlanEdited(false);
    }
  }, [
    pageNumber,
    planEdited,
    planOption,
    queryType,
    rowsPerPage,
    searchedDevice,
    userIsAdmin,
  ]);

  // Linhas da tabela
  const rows = React.useMemo(
    () =>
      devicesList.map((m: any, i: number) => {
        // Data de expiração do plano
        const expiresDate = m?.expires_at?.replaceAll("-", "/").toString();

        // Alterar o formato da data de expiração de acordo com a linguagem
        const convertedExpiresDate =
          language !== "en" ? expiresDate : enExpiresDate(expiresDate);

        // Data de expiração do Plano - 30 dias
        const expDataMinThirtyDays = new Date(
          +convertToDateValue(convertedExpiresDate, language) -
            30 * 86400 * 1000
        ).getTime();

        // Checar se falta 30 dias para o plano se expirar
        const checkRenewDate =
          expDataMinThirtyDays < new Date().getTime() ? true : false;

        // Checar se o plano é Recorrente
        const checkIfPlanIsRecurrent = m?.recorrencia === true;

        // Checar se o plano Recorrente foi cancelado
        const checkIfPlanIsRecurrentIsCanceled = m?.plan.recorrencia_cancelada;

        return {
          id: i + 1,
          planId: m?._id,
          name: m?.device.name,
          mac: m?.mac ?? "plan",
          userId: m?.user_id,
          plan:
            m?.status === undefined
              ? selectNameByTranslations(m, language)
              : checkIfPlanIsRecurrent && !checkIfPlanIsRecurrentIsCanceled
              ? `${selectNameByTranslations(m, language)} - ${t(
                  "TEXT.RECURRENT"
                )}`
              : checkIfPlanIsRecurrent &&
                checkIfPlanIsRecurrentIsCanceled === true
              ? selectNameByTranslations(m, language)
              : selectNameByTranslations(m, language),
          created_at: convertToDatePaymentPending(m?.created_at, language),
          expires_at: m?.expires_at?.replaceAll("-", "/"),
          planType: m?.plan.identifier ?? "",
          status: m?.mac === "-1" ? "AVAILABLE" : m?.status,
          benefits: benefits(m?.plan, language) ?? "",
          alterPlan: "",
          checkRenewDate,
        };
      }),
    [devicesList, language, t]
  );

  // Filtrar status indefinidos
  const filteredRows = React.useMemo(
    () => rows.filter((f: any) => f.status !== undefined),
    [rows]
  );

  // Ações do modal de adicionar Plano
  const handleOpenModalAddPlan = (
    id: string,
    plan: string,
    benefits: any[]
  ) => {
    setModalAddPlanValues({ id, plan, benefits });
    setOpenModalAddPlan(true);
  };
  const handleCloseModalAddPlan = () => {
    setOpenModalAddPlan(false);
  };

  // Alterar o plano
  const handleChangePlan = (e: any, mac: string) => {
    e.stopPropagation();
    navigate(`${PLANS_AVAILABLE}/${mac}`);
  };

  // Mensagem dos dispositivos compartilhados
  const handleSharedDevice = () => {
    toastError(t("TOAST.ERROR.SHARE_DEVICE_PLAN"));
  };

  // Ações do modal de gerar relatório de assinaturas
  const [openModalGenerateReport, setOpenModalGenerateReport] =
    React.useState(false);
  const handleOpenModalGenerateReport = () => {
    setOpenModalGenerateReport(true);
  };
  const handleCloseModalGenerateReport = () => {
    setOpenModalGenerateReport(false);
  };

  // Colunas da tabela
  const columns: GridColDef[] =
    !mobile && !mediumPage && !smallWindow
      ? [
          {
            field: "id",
            hide: true,
          },
          {
            field: "planId",
            hide: true,
          },
          {
            field: "name",
            headerName: t("TEXT.DEVICE"),
            align: "left",
            headerAlign: "left",
            flex: 3,
            renderHeader: () => (
              <p
                style={{
                  fontSize: "12px",
                  color: theme === "light" ? "#7E7E7E" : "inherit",
                  textTransform: "uppercase",
                  fontWeight: 700,
                  marginLeft: "15px",
                }}
              >
                {t("TEXT.DEVICE")}
              </p>
            ),
            renderCell: ({ row }: any) => (
              <List>
                <ListItem alignItems="flex-start">
                  {row.name ? (
                    <ListItemAvatar sx={{ alignSelf: "center", mb: 1 }}>
                      <AvatarIcon name={row.name} bgcolor={red[400]} />
                    </ListItemAvatar>
                  ) : (
                    <Avatar
                      sx={{
                        alignSelf: "center",
                        bgcolor: red[400],
                        mr: 2,
                      }}
                    >
                      <AddIcon />
                    </Avatar>
                  )}
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          sx={{
                            display: "inline",
                            fontWeight: 450,
                            whiteSpace: "break-spaces",
                          }}
                        >
                          {row.name ?? "..."}
                        </Typography>
                      </React.Fragment>
                    }
                    secondary={
                      <React.Fragment>
                        <Box display="flex" mt={0.2} component="span">
                          <Typography
                            component="span"
                            fontWeight={500}
                            sx={{
                              mr: 0.5,
                              mt: 0.1,
                              color: theme === "light" ? "black" : "white",
                              fontSize: 13,
                            }}
                          >
                            MAC:
                          </Typography>
                          <Typography
                            component="span"
                            sx={{ display: "inline" }}
                            variant="body2"
                          >
                            {row.name ? row.mac : "..."}
                          </Typography>
                        </Box>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </List>
            ),
          },
          {
            field: "starts_at",
            headerName: t("TEXT.DATE"),
            align: "center",
            headerAlign: "center",
            flex: 1.5,
            renderCell: ({ row }: any) => (
              <List>
                <ListItem alignItems="flex-start" sx={{ pb: 0 }}>
                  <Box
                    display="flex"
                    ml={row.expires_at && row.planType !== "free_plan" ? 0 : -4}
                  >
                    <Typography fontWeight={500} fontSize={14} mr={0.5}>
                      {t("TEXT.CREATION")}:
                    </Typography>
                    <Typography fontSize={14}>{row.created_at}</Typography>
                  </Box>
                </ListItem>
                {row.expires_at && row.planType !== "free_plan" && (
                  <ListItem
                    alignItems="flex-start"
                    sx={{
                      pt: 0.5,
                      mr: row.expires_at === t("TEXT.UNDETERMINED") ? 0 : 2,
                    }}
                  >
                    <Box display="flex">
                      <Typography fontWeight={500} fontSize={14} mr={0.5}>
                        {t("TEXT.EXPIRATION")}:
                      </Typography>
                      <Typography fontSize={14}>{row.expires_at}</Typography>
                    </Box>
                  </ListItem>
                )}
              </List>
            ),
          },
          {
            field: "plan",
            headerName: t("TEXT.PLAN"),
            align: "center",
            headerAlign: "center",
            flex: 1,
          },
          {
            field: "alterPlan",
            headerName: t("TEXT.CHANGE_PLAN"),
            sortable: false,
            disableColumnMenu: true,
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ row }: any) => (
              <>
                {row.status !== "AVAILABLE" && +row.userId === +userId ? (
                  <Box
                    p={2}
                    pr={1}
                    onClick={(e) => handleChangePlan(e, row.mac)}
                  >
                    <Box display="flex" justifyContent="center">
                      <Tooltip title={t("TEXT.CHANGE_PLAN")}>
                        <IconButton color="success" sx={{ p: 0 }}>
                          <AttachMoneyIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                ) : row.status !== "AVAILABLE" ? (
                  <Box p={2} pr={1} onClick={handleSharedDevice}>
                    <Box display="flex" justifyContent="center">
                      <Tooltip title={t("TEXT.CHANGE_PLAN")}>
                        <IconButton sx={{ p: 0, color: "#B8B8B8" }}>
                          <AttachMoneyIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                ) : (
                  <Box p={2} pr={1}>
                    <Box display="flex" justifyContent="center">
                      <Tooltip title={t("TEXT.LINK_DEVICE")}>
                        <IconButton color="info" sx={{ p: 0 }}>
                          <AttachMoneyIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                )}
                {row.checkRenewDate === true &&
                  !row.plan.includes(t("TEXT.RECURRENT")) && (
                    <Box>
                      {row.status !== "AVAILABLE" && +row.userId !== +userId ? (
                        <Tooltip title={t("TEXT.PLAN_EXPIRED")}>
                          <IconButton
                            onClick={handleSharedDevice}
                            sx={{
                              p: 0,
                              ml: 1,
                              cursor: "pointer",
                              color: "#B8B8B8",
                            }}
                          >
                            <RunningWithErrorsIcon
                              sx={{ fontSize: 26 }}
                              fontSize="large"
                            />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title={t("TEXT.PLAN_EXPIRED")}>
                          <IconButton
                            onClick={(e: any) => {
                              e.stopPropagation();
                              row.status !== "AVAILABLE" &&
                                navigate(
                                  `${PLANS_AND_SUBSCRIPTIONS}/${row.mac}/${row.planId}`
                                );
                            }}
                            sx={{ p: 0, ml: 1, cursor: "pointer" }}
                          >
                            <RunningWithErrorsIcon
                              sx={{ fontSize: 26 }}
                              color="warning"
                              fontSize="large"
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                  )}
              </>
            ),
          },
          {
            field: "status",
            sortable: false,
            disableColumnMenu: true,
            headerName: "Status",
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ row }: any) =>
              row.status && (
                <Chip
                  label={
                    row.status === "ACTIVE"
                      ? t("TEXT.ACTIVE")
                      : row.status === "INACTIVE"
                      ? t("TEXT.INACTIVE")
                      : row.status === "AVAILABLE"
                      ? t("TEXT.AVAILABLE")
                      : row.status === "DELETED"
                      ? t("TEXT.SUSPENDED_SINGULAR")
                      : row.status
                  }
                  color={
                    row.status === "ACTIVE"
                      ? "success"
                      : row.status === "AVAILABLE"
                      ? "info"
                      : "error"
                  }
                  sx={{
                    fontWeight: 700,
                    backgroundColor:
                      row.status === "ACTIVE"
                        ? green[200]
                        : row.status === "AVAILABLE"
                        ? blue[100]
                        : red[100],
                    color:
                      row.status === "ACTIVE"
                        ? green[800]
                        : row.status === "AVAILABLE"
                        ? blue[800]
                        : red[800],
                    textTransform: "uppercase",
                    fontSize: 12,
                    m: 3,
                  }}
                />
              ),
          },
        ]
      : [
          {
            field: "id",
            hide: true,
          },
          {
            field: "planId",
            hide: true,
          },
          {
            field: "alterPlan",
            hide: true,
          },
          {
            field: "mac",
            hide: true,
          },
          {
            field: "plan",
            hide: true,
          },
          {
            field: "status",
            hide: true,
          },
          {
            field: "name",
            headerName: t("TEXT.DEVICES"),
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ row }: any) =>
              row.status && (
                <Box mr="auto">
                  <List>
                    <ListItem alignItems="flex-start" sx={{ pl: 0.5, pr: 0 }}>
                      {row.name ? (
                        <ListItemAvatar sx={{ alignSelf: "center" }}>
                          <AvatarIcon
                            name={row.name ?? "?"}
                            bgcolor={red[400]}
                          />
                        </ListItemAvatar>
                      ) : (
                        <Avatar
                          sx={{
                            alignSelf: "center",
                            bgcolor: red[400],
                            mr: 2,
                          }}
                        >
                          <AddIcon />
                        </Avatar>
                      )}
                      <ListItemText
                        primary={
                          <React.Fragment>
                            <Typography
                              component="span"
                              sx={{
                                display: "inline",
                                fontWeight: 450,
                                whiteSpace: "break-spaces",
                              }}
                            >
                              {row.name ?? "..."}
                            </Typography>
                          </React.Fragment>
                        }
                        secondary={
                          <React.Fragment>
                            <Box display="flex" mt={0.5} component="span">
                              <Typography
                                component="span"
                                fontWeight={500}
                                sx={{
                                  mr: 0.5,
                                  mt: 0.1,
                                  color: theme === "light" ? "black" : "white",
                                  fontSize: 13,
                                }}
                              >
                                MAC:
                              </Typography>
                              <Typography variant="body2" component="span">
                                {row.name ? row.mac : "..."}
                              </Typography>
                            </Box>
                            <Box display="flex" mt={0.8}>
                              <Typography
                                fontWeight={500}
                                sx={{
                                  mr: 0.5,
                                  mt: 0.1,
                                  color: theme === "light" ? "black" : "white",
                                  fontSize: 13,
                                }}
                              >
                                {t("TEXT.START_DATE")}:
                              </Typography>
                              <Typography variant="body2">
                                {row.created_at}
                              </Typography>
                            </Box>
                            {row.expires_at && row.planType !== "free_plan" && (
                              <Box display="flex" mt={0.8}>
                                <Typography
                                  fontWeight={500}
                                  sx={{
                                    mr: 0.5,
                                    mt: 0.1,
                                    color:
                                      theme === "light" ? "black" : "white",
                                    fontSize: 13,
                                  }}
                                >
                                  {t("TEXT.EXPIRATION_DATE")}:
                                </Typography>
                                <Typography variant="body2">
                                  {row.expires_at}
                                </Typography>
                              </Box>
                            )}
                            <Box
                              display="flex"
                              mt={0.5}
                              mb={0.5}
                              component="span"
                            >
                              <Typography
                                component="span"
                                fontWeight={500}
                                sx={{
                                  mr: 0.5,
                                  mt: 0.1,
                                  color: theme === "light" ? "black" : "white",
                                  fontSize: 13,
                                }}
                              >
                                {t("TEXT.PLAN")}:
                              </Typography>
                              <Typography variant="body2" component="span">
                                {row.plan}
                              </Typography>
                            </Box>
                            <Box
                              display="flex"
                              mt={0.5}
                              mb={0.8}
                              component="span"
                            >
                              <Typography
                                component="span"
                                fontWeight={500}
                                sx={{
                                  mr: 0.5,
                                  mt: 0.5,
                                  color: theme === "light" ? "black" : "white",
                                  fontSize: 13,
                                }}
                              >
                                {t("TEXT.CHANGE_PLAN")}:
                              </Typography>
                              {row.status !== "AVAILABLE" &&
                              +row.userId === +userId ? (
                                <IconButton
                                  color="success"
                                  sx={{ p: 0 }}
                                  onClick={(e) => handleChangePlan(e, row.mac)}
                                >
                                  <AttachMoneyIcon />
                                </IconButton>
                              ) : row.status !== "AVAILABLE" ? (
                                <IconButton
                                  sx={{ p: 0, color: "#B8B8B8" }}
                                  onClick={handleSharedDevice}
                                >
                                  <AttachMoneyIcon />
                                </IconButton>
                              ) : (
                                <IconButton color="info" sx={{ p: 0 }}>
                                  <AttachMoneyIcon />
                                </IconButton>
                              )}
                              {row.checkRenewDate === true &&
                                !row.plan.includes(t("TEXT.RECURRENT")) && (
                                  <Box>
                                    {row.status !== "AVAILABLE" &&
                                    +row.userId !== +userId ? (
                                      <Tooltip title={t("TEXT.PLAN_EXPIRED")}>
                                        <IconButton
                                          onClick={handleSharedDevice}
                                          sx={{
                                            p: 0,
                                            ml: 1,
                                            cursor: "pointer",
                                            color: "#B8B8B8",
                                          }}
                                        >
                                          <RunningWithErrorsIcon
                                            sx={{ fontSize: 26 }}
                                            fontSize="large"
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    ) : (
                                      <Tooltip title={t("TEXT.PLAN_EXPIRED")}>
                                        <IconButton
                                          onClick={(e: any) => {
                                            e.stopPropagation();
                                            row.status !== "AVAILABLE" &&
                                              navigate(
                                                `${PLANS_AND_SUBSCRIPTIONS}/${row.mac}/${row.planId}`
                                              );
                                          }}
                                          sx={{
                                            p: 0,
                                            ml: 1,
                                            cursor: "pointer",
                                          }}
                                        >
                                          <RunningWithErrorsIcon
                                            sx={{ fontSize: 26 }}
                                            color="warning"
                                            fontSize="large"
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                  </Box>
                                )}
                            </Box>
                            <Chip
                              component="span"
                              label={
                                row.status === "ACTIVE"
                                  ? t("TEXT.ACTIVE")
                                  : row.status === "INACTIVE"
                                  ? t("TEXT.INACTIVE")
                                  : row.status === "AVAILABLE"
                                  ? t("TEXT.AVAILABLE")
                                  : row.status === "DELETED"
                                  ? t("TEXT.SUSPENDED_SINGULAR")
                                  : row.status
                              }
                              color={
                                row.status === "ACTIVE"
                                  ? "success"
                                  : row.status === "AVAILABLE"
                                  ? "info"
                                  : "error"
                              }
                              sx={{
                                fontWeight: 700,
                                backgroundColor:
                                  row.status === "ACTIVE"
                                    ? green[200]
                                    : row.status === "AVAILABLE"
                                    ? blue[100]
                                    : red[100],
                                color:
                                  row.status === "ACTIVE"
                                    ? green[800]
                                    : row.status === "AVAILABLE"
                                    ? blue[800]
                                    : red[800],
                                textTransform: "uppercase",
                                fontSize: 12,
                              }}
                            />
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                  </List>
                </Box>
              ),
          },
        ];

  // Loading do Spinner inical da página
  React.useEffect(() => {
    const fetchStatus = () => {
      if (filteredRows.length !== 0 && loading) {
        setLoading(false);
      }
    };
    fetchStatus();
    const statusInterval = setInterval(fetchStatus, 1000);
    return () => clearInterval(statusInterval);
  }, [filteredRows.length, loading]);

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 60000);
  }, []);

  // Alterar o filtro da tabela
  const handleChange = (_: any, value: number) => {
    setPlanOption(value);
    if (value === 2 && queryType === "plan") {
      setQueryType("device");
    }
    if (value === 4 && (queryType === "device" || queryType === "mac")) {
      setQueryType("plan");
    }
  };

  // Ação de atualizar tabela
  const reloadTable = () => {
    setLoading(true);
    getPlans(
      pageNumber,
      rowsPerPage,
      searchedDevice,
      queryTypeOptions(planOption, searchedDevice, queryType),
      "",
      0
    ).then((res) => {
      if (res?.data) {
        setDevicesList(res.data.data);
        setTotalPages(res.data.total);
      }
      setLoading(false);
    });
  };

  // Ação ao alterar a página da tabela
  const handleChangePage = (value: number) => {
    setPageNumber(value + 1);
  };

  // Ação de alterar TABs para o modo admin
  const [tabAdmin, setTabAdmin] = React.useState(0);
  const handleChangeTabsAdmin = (
    _event: React.SyntheticEvent,
    newValue: number
  ) => {
    setTabAdmin(newValue);
  };

  return (
    <Box display="grid">
      <Box overflow="auto" whiteSpace="nowrap">
        <Box display="flex" justifyContent="center">
          <Card elevation={3} sx={{ width: 1500, p: mobile ? 0 : 1, mb: 2 }}>
            <CardContent sx={{ p: mobile ? 0 : 1 }}>
              {userIsAdmin && (
                <Tabs
                  value={tabAdmin}
                  onChange={handleChangeTabsAdmin}
                  variant="scrollable"
                  scrollButtons="auto"
                  sx={{ mb: 3 }}
                >
                  <Tab label={t("TEXT.PLANS_AND_SUBSCRIPTIONS")} />
                  <Tab label="Área Admin" />
                </Tabs>
              )}
              {tabAdmin === 0 ? (
                <>
                  <Box display="flex" justifyContent="space-between" ml={2}>
                    <Typography
                      fontSize={mobile ? 22 : 25}
                      gutterBottom
                      sx={{ mt: mobile ? 1 : 0, mb: 0 }}
                    >
                      {t("TEXT.PLANS_AND_SUBSCRIPTIONS")}
                    </Typography>
                    <Tooltip title={t("TEXT.UPDATE") as string}>
                      <IconButton
                        color="warning"
                        onClick={reloadTable}
                        sx={{ mt: mobile ? 0.5 : 0 }}
                      >
                        <ReplayIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Card elevation={0}>
                    <CardContent>
                      {loading ? (
                        <Box
                          m={
                            mobile
                              ? "230px 0px"
                              : window.screen.width < 1550
                              ? "290px 0px"
                              : "470px 0px"
                          }
                        >
                          <Spinner />
                        </Box>
                      ) : (
                        <>
                          <Tabs
                            value={planOption}
                            onChange={handleChange}
                            orientation="horizontal"
                            variant="scrollable"
                            allowScrollButtonsMobile
                            sx={{
                              fontSize: 20,
                              pb: 2,
                              pt: 2,
                              placeContent: "center",
                            }}
                          >
                            <Tab
                              icon={<ListAltIcon />}
                              label={t("TEXT.ALL")}
                              disableRipple
                              sx={{ pt: 0 }}
                            />
                            <Tab
                              icon={<CheckCircleOutlineIcon />}
                              label={`${t("TEXT.ACTIVE")}s`}
                              disableRipple
                              sx={{ pt: 0 }}
                            />
                            <Tab
                              icon={<CardGiftcardIcon />}
                              label={t("TEXT.FREE")}
                              sx={{ pt: 0 }}
                              disableRipple
                            />
                            <Tab
                              icon={
                                <Box display="flex">
                                  <RunningWithErrorsIcon />
                                  {plansToExpire > 0 && (
                                    <Tooltip title={t("TEXT.PLAN_EXPIRE_MSG")}>
                                      <Avatar
                                        sx={{
                                          bgcolor: red[600],
                                          fontSize: 13,
                                          fontWeight: 600,
                                          mt: -0.5,
                                          ml: 0.2,
                                          width: 20,
                                          height: 20,
                                        }}
                                        alt=""
                                      >
                                        {plansToExpire}
                                      </Avatar>
                                    </Tooltip>
                                  )}
                                </Box>
                              }
                              label={t("TEXT.TO_EXPIRE")}
                              sx={{ pt: 0 }}
                              disableRipple
                            />
                            <Tab
                              icon={<AddCircleOutlineIcon />}
                              label={t("TEXT.AVAILABLE_PLURAL")}
                              sx={{ pt: 0 }}
                              disableRipple
                            />
                          </Tabs>
                          <FormControl sx={{ mt: 1 }}>
                            <FormLabel>{t("BUTTON.SEARCH")}:</FormLabel>
                            <RadioGroup
                              row
                              value={queryType}
                              onChange={handleChangeSearch}
                            >
                              {planOption !== 4 && (
                                <FormControlLabel
                                  value="device"
                                  control={<Radio />}
                                  label={t("TEXT.DEVICE")}
                                />
                              )}
                              {planOption !== 4 && (
                                <FormControlLabel
                                  value="mac"
                                  control={<Radio />}
                                  label="MAC"
                                />
                              )}
                              {planOption !== 2 && (
                                <FormControlLabel
                                  value="plan"
                                  control={<Radio />}
                                  label={t("TEXT.PLAN")}
                                />
                              )}
                            </RadioGroup>
                          </FormControl>
                          <Paper
                            component="form"
                            sx={{
                              p: "2px 4px",
                              display: "flex",
                              alignItems: "center",
                              mr: mobile ? 2 : 4,
                              borderRadius: 5,
                              width: "100%",
                              mt: 1,
                              mb: 3,
                              backgroundColor:
                                theme === "dark" ? "#181818" : "#F9F9F9",
                            }}
                          >
                            <Tooltip title={t("BUTTON.SEARCH")}>
                              <IconButton
                                type="button"
                                sx={{ p: "10px" }}
                                aria-label="search"
                                onClick={(e: any) => {
                                  handleSearch(searchedClicked);
                                  e.preventDefault();
                                  if (isSearched) {
                                    setIsSearched(false);
                                  }
                                }}
                              >
                                <SearchIcon />
                              </IconButton>
                            </Tooltip>
                            <InputBase
                              id="myInput"
                              onKeyDown={(e: any) => {
                                if (e.key === "Enter") {
                                  handleSearch(e.target.value);
                                  e.preventDefault();
                                  if (isSearched) {
                                    setIsSearched(false);
                                  }
                                }
                              }}
                              onChange={(e: any) => {
                                setSearchedClicked(e.target.value);
                              }}
                              sx={{ ml: 2, flex: 1 }}
                              placeholder={t("TEXT.SEARCH_DEVICE")}
                            />
                            <Tooltip title={t("TEXT.CLEAN")}>
                              <IconButton
                                type="button"
                                sx={{ p: "10px" }}
                                aria-label="search"
                                onClick={handleClearSearch}
                              >
                                <ClearIcon />
                              </IconButton>
                            </Tooltip>
                          </Paper>
                          <Paper
                            style={
                              userIsAdmin
                                ? {
                                    height: mobile
                                      ? 380
                                      : mediumPage
                                      ? 735
                                      : window.screen.width < 1550
                                      ? 380
                                      : 735,
                                    cursor: "pointer",
                                  }
                                : {
                                    height: mobile
                                      ? 350
                                      : mediumPage
                                      ? 835
                                      : window.screen.width < 1550
                                      ? 480
                                      : 835,
                                    cursor: "pointer",
                                  }
                            }
                            elevation={0}
                          >
                            <DataGridServerSide
                              rows={filteredRows}
                              isRowSelectable={(params: GridRowParams) =>
                                +params.row.userId === +userId
                              }
                              columns={columns}
                              message={t("TEXT.NO_SIGNATURE")}
                              getRowHeight={() => "auto"}
                              pageNumber={pageNumber}
                              totalPages={totalPages}
                              onRowClick={(params: any) => {
                                if (params.row.status !== "AVAILABLE") {
                                  if (params.row.userId === +userId) {
                                    navigate(
                                      `${PLANS_AND_SUBSCRIPTIONS}/${params.row.mac}/${params.row.planId}`
                                    );
                                  }
                                } else {
                                  handleOpenModalAddPlan(
                                    params.row.planId,
                                    params.row.plan,
                                    params.row.benefits
                                  );
                                }
                              }}
                              onPageChange={(newPage: number) => {
                                handleChangePage(newPage);
                              }}
                              onPageSizeChange={(rowsPerPage: any) =>
                                setRowsPerPage(rowsPerPage)
                              }
                              disableSelectionOnClick
                            />
                          </Paper>
                        </>
                      )}
                    </CardContent>
                  </Card>
                </>
              ) : (
                <>
                  <Box display="flex" justifyContent="space-between" ml={2}>
                    <Typography
                      fontSize={mobile ? 22 : 25}
                      gutterBottom
                      sx={{ mt: mobile ? 1 : 0, mb: 0 }}
                    >
                      {t("TEXT.ADMIN_AREA")}
                    </Typography>
                    <Box display="flex">
                      <Tooltip title={t("TEXT.SUBSCRIPTIONS_REPORT")}>
                        <Button
                          size="small"
                          color="warning"
                          startIcon={<FileDownloadOutlinedIcon />}
                          onClick={handleOpenModalGenerateReport}
                          sx={{
                            fontSize: 13,
                            fontWeight: 600,
                            mt: mobile ? 1 : 0,
                            mr: mobile ? 1 : 2,
                          }}
                        >
                          {t("TEXT.REPORT")}
                        </Button>
                      </Tooltip>
                    </Box>
                  </Box>
                  <Card elevation={0}>
                    <CardContent>
                      <AdminArea />
                    </CardContent>
                  </Card>
                </>
              )}
            </CardContent>
          </Card>
        </Box>
      </Box>
      {modalAddPlanValues && (
        <ModalAddPlan
          benefits={modalAddPlanValues.benefits}
          plan={modalAddPlanValues.plan}
          id={modalAddPlanValues.id}
          handleOpen={openModalAddPlan}
          setPlanEdited={setPlanEdited}
          handleClose={handleCloseModalAddPlan}
          userIsAdmin={userIsAdmin}
        />
      )}
      <ModalSignatureReport
        handleOpen={openModalGenerateReport}
        handleClose={handleCloseModalGenerateReport}
      />
    </Box>
  );
};

export default PlansAndSubscriptions;
